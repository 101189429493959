import { useMemo, useState } from 'react';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import { Dimensions, Metrics } from 'components/insights/constants';
import { DrillableArrow, DrillableItem } from 'components/insights/layout/details/drillableTableComponents';
import { useTableConfig } from 'components/insights/layout/details/helpers/dimensionsUtils';
import useItemDrilldown from 'components/insights/layout/details/helpers/hooks/useItemDrilldown';
import MetricDetailBreadcrumbs from 'components/insights/layout/details/MetricDetailBreadcrumbs';
import TableHeader from 'components/insights/layout/details/TableHeader';
import TableTitle from 'components/insights/layout/details/TableTitle';
import AnalyticsResponseData from 'components/insights/query/AnalyticsResponseData';
import AnalyticsResponseRollupCollection from 'components/insights/query/AnalyticsResponseRollupCollection';
import { AnalyticsQueryParams } from 'components/insights/query/useAnalyticsInfiniteQuery';
import DataTable from 'components/shared/DataTable';
import { useProfile } from 'context/profile';
import { useInsightsStore } from 'stores/insightsStore';

import ApiExport from '../ApiExport';
import { CategoryRow } from '../helpers/utils';
import MetricDiffBadge, { Comparator } from '../MetricDiffBadge';
import TableContainer from '../TableContainer';

export type OesTableRow = CategoryRow & {
  score: { current: number | null; prior: number | null };
};

type Props = {
  data: AnalyticsResponseData;
  loading: boolean;
  requestParams: AnalyticsQueryParams;
  rollups: AnalyticsResponseRollupCollection;
  onSelectedDimensionsChange: (selectedDimensions: Dimensions[]) => void;
  selectedDimensions: Dimensions[];
};

const OlioEngagementScoreDataTable = ({
  loading,
  requestParams,
  rollups,
  onSelectedDimensionsChange,
  selectedDimensions,
  data,
}: Props) => {
  const { profile } = useProfile();

  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);

  const { tableHeader, tableItemFilterKey, subDimensions } = useTableConfig(
    selectedDimensions[0],
    selectedGroupType,
    profile.canActAsManager
  );

  const handleItemDrilldown = useItemDrilldown({
    series: data.dimensions.series,
    tableHeader,
    tableItemFilterKey,
    selectedDimensions,
    onSelectedDimensionsChange,
    subDimensions,
  });

  const [hoveredRowId, setHoveredRowId] = useState('');

  const categories = useMemo(() => data.dimensions.getCurrentDimensionValuesByIndex(0), [data.dimensions]);

  const overallScore = rollups.overall.metrics.getCurrentSeries(Metrics.OLIO_ENGAGEMENT_SCORE);
  const sumLocationEpisodes = rollups.overall.metrics.getCurrentSeries(Metrics.LOCATION_EPISODE_ID_COUNT);

  const scores = useMemo(() => data.metrics.getCurrentSeries(Metrics.OLIO_ENGAGEMENT_SCORE), [data.metrics]);
  const priorPeriodScores = useMemo(() => data.metrics.getPriorSeries(Metrics.OLIO_ENGAGEMENT_SCORE), [data.metrics]);
  const locationEpisodeCounts = useMemo(
    () => data.metrics.getCurrentSeries(Metrics.LOCATION_EPISODE_ID_COUNT),
    [data.metrics]
  );

  const tableData = useMemo<OesTableRow[]>(
    () =>
      categories.map((categoryName, i) => ({
        categoryName,
        score: {
          current: scores[i],
          prior: priorPeriodScores[i],
        },
        locationEpisodes: locationEpisodeCounts[i] ?? 0,
      })),
    [locationEpisodeCounts, categories, scores, priorPeriodScores]
  );

  const columnHelper = createColumnHelper<OesTableRow>();

  const columns = useMemo<ColumnDef<OesTableRow, any>[]>(
    () => [
      columnHelper.accessor('categoryName', {
        header: tableHeader,
        cell: (cellData) => (
          <DrillableItem drillable={!!subDimensions} isRowHovered={hoveredRowId === cellData.row.id}>
            {cellData.getValue()}
          </DrillableItem>
        ),
      }),
      columnHelper.accessor('locationEpisodes', {
        header: 'Patients',
        footer: () => `${sumLocationEpisodes ?? '—'} (Total)`,
      }),
      columnHelper.accessor('score', {
        header: 'Olio Engagement Score (OES)',
        cell: (data) => (
          <>
            <p>{data.getValue().current == null ? <>&mdash;</> : `${data.getValue().current}%`}</p>{' '}
            <MetricDiffBadge
              currentValue={data.getValue().current}
              priorValue={data.getValue().prior}
              comparator={Comparator.GREATER_THAN}
              formatter={(val) => `${val.toFixed()}%`}
            />
            {subDimensions && hoveredRowId === data.row.id && <DrillableArrow width={9} height={9} />}
          </>
        ),
        footer: () => `${overallScore ?? '—'}%`,
      }),
    ],
    [columnHelper, tableHeader, subDimensions, hoveredRowId, sumLocationEpisodes, overallScore]
  );

  const desktopOnlyColumns = ['locationEpisodes'];

  const defaultSort = [
    {
      id: 'score',
      desc: true,
    },
  ];

  return (
    <TableContainer>
      <TableHeader>
        <TableHeader.Row>
          <TableTitle>Olio Engagement Score (OES) Overview</TableTitle>
          <ApiExport
            disabled={!tableData.length}
            show={!loading}
            params={requestParams}
            filename='olio-engagement-score-export'
          />
        </TableHeader.Row>
        <MetricDetailBreadcrumbs onSelectedDimensionsChange={onSelectedDimensionsChange} />
      </TableHeader>
      <DataTable
        columns={columns}
        data={tableData}
        defaultSortBy={defaultSort}
        desktopOnlyColumns={desktopOnlyColumns}
        loading={loading}
        drillable={!!subDimensions}
        setHoveredRowId={setHoveredRowId}
        handleItemDrilldown={handleItemDrilldown}
      />
    </TableContainer>
  );
};

export default OlioEngagementScoreDataTable;
