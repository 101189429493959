import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import usePrevious from 'hooks/usePrevious';
import { LabelBold, LabelSmall } from 'styles/typography';

import colors from '../../styles/theme/colors';

interface ProgressBarProps {
  backgroundColor?: string;
  color?: string;
  completedColor?: string;
  height?: number;
  max?: number;
  showLabel?: boolean;
  value?: number;
}

function ProgressBar(props: ProgressBarProps) {
  const { backgroundColor, color, completedColor, height = 7, max = 100, showLabel = true, value = 0 } = props;

  const currentProgress = value / max;
  const currentProgressPercent = currentProgress * 100;
  const previousValue = usePrevious(value);
  const [progressStart, setProgressStart] = useState<Date | null>(null);

  useEffect(() => {
    if (value && !previousValue) {
      setProgressStart(new Date());
    }
    // this one is fine
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const timeRemaining = useMemo(() => {
    if (!progressStart) return '';
    if (value === max) return '— Complete';

    const elapsedTime = new Date().getTime() - progressStart.getTime();
    const estimatedTotalTime = elapsedTime / currentProgress;
    const estimatedRemainingTime = Math.round((estimatedTotalTime - elapsedTime) / 1000);

    return `— ${estimatedRemainingTime} seconds left`;
    // this one is fine
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProgress]);

  return (
    <Container>
      <ProgressBarContainer backgroundColor={backgroundColor} height={height}>
        <ProgressBarProgress
          color={color}
          completedColor={completedColor}
          height={height}
          $progressPercent={currentProgressPercent}
        />
      </ProgressBarContainer>
      {showLabel && (
        <ProgressLabel>
          <LabelBold>{Math.round(currentProgressPercent)}%</LabelBold>
          <LabelSmall color={colors.black75}>{timeRemaining}</LabelSmall>
        </ProgressLabel>
      )}
    </Container>
  );
}

export default ProgressBar;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-end;
`;

const ProgressBarContainer = styled.div<{
  height: number;
  backgroundColor?: string;
}>`
  width: 100%;
  border-radius: ${({ height }) => height}px;
  background-color: ${({ backgroundColor }) => backgroundColor || colors.white};
  height: ${({ height }) => height}px;
`;
const ProgressBarProgress = styled.div<{
  height: number;
  $progressPercent: number;
  completedColor?: string;
  color?: string;
}>`
  border-radius: ${({ height }) => height}px;
  width: ${({ $progressPercent }) => $progressPercent}%;
  background-color: ${({ color, completedColor, $progressPercent }) => {
    if ($progressPercent === 100) {
      return completedColor || colors.accentGreen;
    }

    return color || colors.primaryBlue;
  }};
  height: ${({ height }) => height}px;
`;

const ProgressLabel = styled.div`
  margin-top: 4px;
`;
