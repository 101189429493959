import styled from 'styled-components';

import { QuestionTypeConfig } from 'models/QuestionTemplate';
import { colors } from 'styles/theme/colors';
import { BodySmall } from 'styles/typography';

type NotesQuestionType = {
  config: QuestionTypeConfig;
  value?: string;
  setValue: (v: string) => void;
  text: string;
  index?: number;
};

export default function NotesQuestion(props: NotesQuestionType) {
  const { config, text, value, setValue, index = -1 } = props;

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  return (
    <QuestionContainer>
      <StyledQuestion>
        {index >= 0 && <span>{index}.</span>}
        <span>
          {text}
          {config.optional && ' (optional)'}
        </span>
      </StyledQuestion>
      <TextArea placeholder='Start typing here to add details' value={value} onChange={handleTextAreaChange} />
    </QuestionContainer>
  );
}

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > *:first-child {
    flex: 1;
  }
`;

const StyledQuestion = styled(BodySmall)`
  display: flex;
  gap: 8px;
`;

const TextArea = styled.textarea`
  margin-top: 8px;
  padding: 12px 16px 16px 16px;
  box-sizing: border-box;
  min-height: 120px;
  border: 1px solid ${colors.black25};
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  display: flex;
  flex-direction: column;

  &::placeholder {
    color: ${colors.black25};
  }
  font-size: 14px !important;
  line-height: 24px !important;
  width: 100%;
  outline: none;
  resize: none;
  background-color: transparent;
  caret-color: ${colors.black};
`;
