import React from 'react';

const MenuHorizontalIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='12px' height='12px' viewBox='0 0 12 12' {...props}>
    <g transform='translate(0, 0)'>
      <circle cx='6' cy='6' r='1.5' fill='#444444' data-color='color-2'></circle>
      <circle cx='10.5' cy='6' r='1.5' fill='#444444'></circle>
      <circle cx='1.5' cy='6' r='1.5' fill='#444444'></circle>
    </g>
  </svg>
);

export default MenuHorizontalIcon;
