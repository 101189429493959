import React, { useEffect, useRef, useState } from 'react';
import ReactSelect, { components, FormatOptionLabelMeta } from 'react-select';
import styled from 'styled-components';

import { InfiniteData, UseInfiniteQueryResult } from '@tanstack/react-query';

import CircularProgress from 'components/shared/CircularProgress';
import { customTheme } from 'components/shared/form/Select/customStyles';
import { useDebounce } from 'hooks/useDebounce';
import { Paginated } from 'models/Paginated';
import { colors } from 'styles/theme/colors';
import { BodySmall } from 'styles/typography';
import CheckboxOffIcon from 'svg/CheckboxOffIcon';
import CheckboxOnIcon from 'svg/CheckboxOnIcon';
import DropdownCaretIcon from 'svg/DropdownCaretIcon';
import DropdownClearIcon from 'svg/DropdownClearIcon';

function isPagedResource<T>(
  x: undefined | UseInfiniteQueryResult<InfiniteData<Paginated<T>>> | T[]
): x is UseInfiniteQueryResult<InfiniteData<Paginated<T>>> {
  return (x && !Array.isArray(x)) ?? false;
}

const LoadingContainer = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 100%;
  margin: 8px 0;
`;

function LoadingState() {
  return (
    <LoadingContainer>
      <CircularProgress color={colors.black25} size={24} thickness={2} />
    </LoadingContainer>
  );
}

type InfiniteScrollMenuListType = {
  isLoading: boolean;
  hasMore: boolean;
  onEndReached: () => void;
};
function InfiniteScrollMenuList(props: { children: any; selectProps: InfiniteScrollMenuListType }) {
  const { hasMore = false, isLoading = false, onEndReached = () => {} } = props.selectProps;
  const menuListRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (menuListRef.current) {
      menuListRef.current.onscroll = ({ target }) => {
        if (!target) return;
        const castTarget = target as any;
        const distanceFromBottom = castTarget.scrollHeight - (castTarget.clientHeight + castTarget.scrollTop);
        if (distanceFromBottom < 100 && hasMore && !isLoading) {
          onEndReached();
        }
      };
    }
  }, [isLoading, hasMore, onEndReached]);
  return (
    <components.MenuList {...(props as any)} innerRef={menuListRef}>
      {props.children}
      {isLoading && <LoadingState />}
    </components.MenuList>
  );
}

function getDefaultStyles(multi = false) {
  const getBorderColor = ({ isFocused, selectProps, isDisabled, hasValue }: any) => {
    let borderColor = colors.black25;

    if (isFocused || (hasValue && multi)) {
      borderColor = colors.primaryBlue;
    }

    if (selectProps.hasError) {
      borderColor = colors.accentRed;
    }

    if (isDisabled) {
      borderColor = 'transparent';
    }

    return borderColor;
  };
  return {
    control: (provided, state) => ({
      ...provided,
      borderWidth: '1px',
      fontSize: '14px',
      lineHeight: '20px',
      boxShadow: 'none',
      borderColor: getBorderColor(state),
      '&:hover': {
        borderColor: getBorderColor(state),
        borderWidth: '1px',
      },
      backgroundColor: state.isDisabled
        ? colors.black10
        : multi && state.hasValue
          ? colors.primaryBlue05
          : provided.backgroundColor,
      pointerEvents: state.isDisabled ? 'auto' : 'inherit',
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    }),
    input: (provided) => ({
      ...provided,
      paddingTop: 4,
      paddingBottom: 4,
    }),
    menu: (provided) => ({
      ...provided,
      fontSize: '14px',
      lineHeight: '20px',
      textOverflow: 'ellipsis',
      zIndex: 10,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: colors.black25,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (_: any, state: any) => ({
      color: state.isDisabled ? colors.black25 : colors.black75,
      width: '24px',
      marginRight: '4px',
      padding: '9px 8px 8px 2px',
      boxSizing: 'border-box' as any,
      display: 'flex',
    }),
    indicatorsContainer: (provided, state) => ({
      display: state.isDisabled ? 'none' : provided.display,
    }),
    multiValue: (provided) => ({
      ...provided,
      borderRadius: 3,
      backgroundColor: colors.black10,
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      ...(state.isDisabled ? { paddingRight: 6 } : {}),
    }),
    multiValueRemove: (provided, state) => {
      return state.isDisabled ? { ...provided, display: 'none' } : provided;
    },
  };
}

type AllowedReactSelectProps = Partial<
  Omit<React.ComponentProps<typeof ReactSelect>, 'options' | 'onChange' | 'value' | 'getOptionValue' | 'getOptionLabel'>
>;
type SelectType<T> = AllowedReactSelectProps & {
  options: T[] | UseInfiniteQueryResult<InfiniteData<Paginated<T>>>;
  populateDefault?: boolean;
  getOptionValue: (obj: T) => string;
  getOptionLabel?: (obj: T) => string;
  formatOptionLabel?: (obj: T, options?: FormatOptionLabelMeta<T>) => React.JSX.Element | null | string | any;
  placeholder?: string;
  onChange: (change: T) => void;
  value?: T | null;
  disabled?: boolean;
  hasError?: boolean;
  onDebouncedSearch?: (search: string) => void;
  prependedOptions?: T[];
  appendedOptions?: T[];
};

export function Select<T>(props: SelectType<T>) {
  const {
    options,
    populateDefault = false,
    getOptionLabel,
    formatOptionLabel,
    getOptionValue,
    placeholder,
    onChange,
    value,
    disabled = false,
    onDebouncedSearch,
    prependedOptions = [],
    appendedOptions = [],
    ...reactSelectProps
  } = props;

  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search);
  const prevDebouncedSearch = useRef<string>(debouncedSearch);

  const _options = prependedOptions
    .concat(isPagedResource(options) ? (options.data?.pages.flatMap((p) => p.data) ?? []) : options)
    .concat(appendedOptions);

  useEffect(() => {
    if (debouncedSearch !== prevDebouncedSearch.current) {
      prevDebouncedSearch.current = debouncedSearch;
      onDebouncedSearch?.(debouncedSearch);
    }
  }, [debouncedSearch, onDebouncedSearch]);

  const selectProps: InfiniteScrollMenuListType = {
    hasMore: isPagedResource(options) ? options.hasNextPage : false,
    isLoading: isPagedResource(options) ? options.isLoading : false,
    onEndReached: function (): void {
      if (isPagedResource(options)) {
        options.fetchNextPage({ cancelRefetch: false });
      }
    },
    onMenuOpen: function (): void {
      if (isPagedResource(options) && !options.isFetched) {
        options.fetchNextPage();
      }
    },
    ...(isPagedResource(options) ? { filterOption: () => true } : {}), // whether or not to use client side filtering
    ...reactSelectProps,
  };
  const hasOptions = isPagedResource(options) ? _options.length > 0 : options.length > 0;
  useEffect(() => {
    if (populateDefault && hasOptions && !value) {
      onChange(options[0]);
    }
  }, [hasOptions, onChange, options, value, populateDefault]);

  return (
    <ReactSelect
      isDisabled={disabled}
      placeholder={placeholder}
      options={_options}
      onChange={(x) => onChange(x as T)}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      formatOptionLabel={formatOptionLabel}
      value={value}
      onInputChange={setSearch}
      inputValue={search ?? ''}
      components={{
        LoadingIndicator: undefined,
        MenuList: InfiniteScrollMenuList as unknown as undefined, //react-select's typescript support is bad, and it brings out something ugly in me.
        Placeholder: ({ children: _, ...props }) => {
          return (
            <components.Placeholder {...props}>
              {props.isFocused ? (
                <StyledPlaceholder>Type to search...</StyledPlaceholder>
              ) : (
                <StyledPlaceholder>{placeholder}</StyledPlaceholder>
              )}
            </components.Placeholder>
          );
        },
      }}
      styles={getDefaultStyles()}
      theme={customTheme}
      loadingMessage={() => ''}
      {...(selectProps as unknown as Record<string, never>)}
    />
  );
}

type MultiSelectChipsType<T> = Omit<SelectType<T>, 'value' | 'onChange' | 'populateDefault'> & {
  onChange: (change: T[]) => void;
  values?: T[] | null;
};
export function MultiSelectChips<T>(props: MultiSelectChipsType<T>) {
  const {
    options,
    getOptionLabel,
    formatOptionLabel,
    getOptionValue,
    placeholder,
    onChange,
    values,
    disabled = false,
    prependedOptions = [],
    appendedOptions = [],
    onDebouncedSearch,
    ...reactSelectProps
  } = props;

  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search);
  const prevDebouncedSearch = useRef<string>(debouncedSearch);

  const _options = prependedOptions
    .concat(isPagedResource(options) ? (options.data?.pages.flatMap((p) => p.data) ?? []) : options)
    .concat(appendedOptions);

  const selectProps: InfiniteScrollMenuListType = {
    hasMore: isPagedResource(options) ? options.hasNextPage : false,
    isLoading: isPagedResource(options) ? options.isLoading : false,
    onEndReached: function (): void {
      if (isPagedResource(options)) {
        options.fetchNextPage({ cancelRefetch: false });
      }
    },
    onMenuOpen: function (): void {
      // setTrackOpen(true);
      if (isPagedResource(options) && !options.isFetched) {
        options.fetchNextPage();
      }
    },
    ...(isPagedResource(options) ? { filterOption: () => true } : {}), // whether or not to use client side filtering
    ...reactSelectProps,
  };

  useEffect(() => {
    if (debouncedSearch !== prevDebouncedSearch.current) {
      prevDebouncedSearch.current = debouncedSearch;
      onDebouncedSearch?.(debouncedSearch);
    }
  }, [debouncedSearch, onDebouncedSearch]);

  return (
    <ReactSelect
      {...(selectProps as unknown as Record<string, never>)}
      isMulti
      isClearable
      isDisabled={disabled}
      placeholder={placeholder}
      options={_options}
      closeMenuOnSelect={false}
      blurInputOnSelect={false}
      onChange={(x) => onChange(x as T[])}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      formatOptionLabel={formatOptionLabel}
      onInputChange={setSearch}
      inputValue={search}
      value={values}
      components={{
        LoadingIndicator: undefined,
        MenuList: InfiniteScrollMenuList as unknown as undefined, //react-select's typescript support is bad, and it brings out something ugly in me.
        Placeholder: ({ children: _, ...props }) => {
          return (
            <components.Placeholder {...props}>
              {props.isFocused ? (
                <StyledPlaceholder>Type to search...</StyledPlaceholder>
              ) : (
                <StyledPlaceholder>{placeholder || 'Select...'}</StyledPlaceholder>
              )}
            </components.Placeholder>
          );
        },
      }}
      styles={getDefaultStyles()}
      theme={customTheme}
      loadingMessage={() => ''}
    />
  );
}

const StyledCheckbox = styled.div`
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  padding: 8px 12px;
  & label {
    cursor: pointer;
  }
  &:hover {
    background-color: var(--primary-blue-25);
  }
`;
const CheckboxContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 0;
  margin-top: -1px;
  min-width: 24px;
  min-height: 24px;
`;
const MultiSelectLeft = styled.span<{ $hasValue: boolean }>`
  color: ${({ $hasValue }) => ($hasValue ? colors.primaryBlue : colors.black50)};
`;
const MultiSelectRight = styled.span<{ $hasValue: boolean }>`
  color: ${({ $hasValue }) => ($hasValue ? colors.primaryBlue : colors.black)};
`;
const StyledPlaceholder = styled.div<any>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 85%;
`;
export type MultiSelectType<T> = {
  options: T[] | UseInfiniteQueryResult<InfiniteData<Paginated<T>>>;
  getOptionValue: (obj: T) => string;
  getOptionLabel?: (obj: T) => string;
  formatOptionLabel?: (obj: T) => React.JSX.Element | null | string;
  placeholder?: string;
  onChange: (change: T[]) => void;
  value?: T[];
  prependedOptions?: T[];
  appendedOptions?: T[];
  onDebouncedSearch?: (search: string) => void;
};

export function MultiSelect<T>(props: MultiSelectType<T>) {
  const {
    options,
    getOptionLabel,
    formatOptionLabel,
    getOptionValue,
    placeholder,
    onChange,
    value,
    prependedOptions = [],
    appendedOptions = [],
    onDebouncedSearch,
    ...reactSelectProps
  } = props;

  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search);
  const prevDebouncedSearch = useRef<string>(debouncedSearch);

  const _options = prependedOptions
    .concat(isPagedResource(options) ? (options.data?.pages.flatMap((p) => p.data) ?? []) : options)
    .concat(appendedOptions);

  const selectProps = {
    hasMore: isPagedResource(options) ? options.hasNextPage : false,
    isLoading: isPagedResource(options) ? options.isLoading : false,
    onEndReached: function (): void {
      if (isPagedResource(options)) {
        options.fetchNextPage({ cancelRefetch: false });
      }
    },
    onMenuOpen: function (): void {
      if (isPagedResource(options) && !options.isFetched) {
        options.fetchNextPage();
      }
    },
    ...(isPagedResource(options) ? { filterOption: () => true } : {}), // whether or not to use client side filtering
    ...reactSelectProps,
  };

  useEffect(() => {
    if (debouncedSearch !== prevDebouncedSearch.current) {
      prevDebouncedSearch.current = debouncedSearch;
      onDebouncedSearch?.(debouncedSearch);
    }
  }, [debouncedSearch, onDebouncedSearch]);

  const handleInputChange = (val, { action }) => {
    if (action !== 'set-value') {
      setSearch(val);
    }
  };

  return (
    <ReactSelect
      isMulti
      isClearable
      {...(selectProps as unknown as Record<string, never>)}
      placeholder={placeholder}
      closeMenuOnSelect={false}
      blurInputOnSelect={false}
      hideSelectedOptions={false}
      controlShouldRenderValue={false}
      options={_options}
      onChange={(x) => onChange(x as T[])}
      onInputChange={handleInputChange}
      inputValue={search}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
      formatOptionLabel={formatOptionLabel}
      value={value}
      loadingMessage={() => ''}
      components={{
        LoadingIndicator: undefined,
        Option: ({ data, isSelected, children, selectOption }) => (
          <StyledCheckbox onClick={() => selectOption(data)}>
            <CheckboxContainer>
              {isSelected ? (
                <CheckboxOnIcon color={colors.primaryBlue} width={14} height={14} />
              ) : (
                <CheckboxOffIcon color={colors.black} width={14} height={14} />
              )}
            </CheckboxContainer>
            <BodySmall>{children}</BodySmall>
          </StyledCheckbox>
        ),
        MenuList: InfiniteScrollMenuList as unknown as undefined, //react-select's typescript support is bad, and it brings out something ugly in me.
        Placeholder: ({ children: _, ...props }) => {
          const selectedValues = props.getValue();
          return (
            <components.Placeholder {...props}>
              {props.isFocused && selectedValues.length == 0 ? (
                <MultiSelectLeft $hasValue={false}>Type to search...</MultiSelectLeft>
              ) : (
                <StyledPlaceholder>
                  <MultiSelectLeft $hasValue={selectedValues.length > 0}>{placeholder}: </MultiSelectLeft>
                  <MultiSelectRight $hasValue={selectedValues.length > 0}>
                    {selectedValues.length || 'All'}
                  </MultiSelectRight>
                </StyledPlaceholder>
              )}
            </components.Placeholder>
          );
        },
        DropdownIndicator: (props: any) => (
          <components.DropdownIndicator {...props}>
            <DropdownCaretIcon />
          </components.DropdownIndicator>
        ),
        ClearIndicator: (props: any) => (
          <components.ClearIndicator {...props}>
            <DropdownClearIcon />
          </components.ClearIndicator>
        ),
      }}
      styles={getDefaultStyles(true)}
    />
  );
}
