function ArrowIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='12' fill='none' viewBox='0 0 18 12' {...props}>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.969'
        d='M17 5.674H1M5.854 10.348 1 5.674 5.854 1'
      />
    </svg>
  );
}
export default ArrowIcon;
