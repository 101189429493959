export default function ActivityDischargeQuestionsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}>
      <defs>
        <path
          d='M1.87878788,2 C2.09042424,2 2.52515152,2.34036364 2.59660606,2.39163636 L6.01442424,4.83745455 C6.15733333,4.94054545 6.24242424,5.10527273 6.24242424,5.28145455 L6.24242424,13.4545455 C6.24242424,13.6590909 6.12842424,13.8461818 5.94678788,13.94 C5.86769697,13.9798182 5.78206061,14 5.6969697,14 C5.58515152,14 5.47442424,13.9661818 5.38006061,13.898 L1.56187879,11.1707273 C1.41842424,11.0687273 1.33333333,10.9034545 1.33333333,10.7272727 L1.33333333,2.54545455 C1.33333333,2.24381818 1.57769697,2 1.87878788,2 Z M8.42424242,2 C8.72533333,2 8.96969697,2.24381818 8.96969697,2.54545455 L8.96969697,2.54545455 L8.96969697,6.36363636 L11.1515152,6.36363636 L11.1515152,4.18181818 L14.4242424,6.90909091 L11.1515152,9.63636364 L11.1515152,7.45454545 L8.96969697,7.45454545 L8.96969697,10.7272727 C8.96969697,11.0289091 8.72533333,11.2727273 8.42424242,11.2727273 L8.42424242,11.2727273 L7.33333333,11.2727273 L7.33333333,5.28145455 C7.33333333,4.75618182 7.07806061,4.25872727 6.64933333,3.95054545 L6.64933333,3.95054545 L3.92478788,2 Z'
          id='dischargeQuestionsPath'
        />
      </defs>
      <g id='discharge-templates' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Discharge-Activity-Item' transform='translate(-606.000000, -512.000000)'>
          <g id='Group-27' transform='translate(592.000000, 498.000000)'>
            <g id='Group-2' transform='translate(14.000000, 14.000000)'>
              <rect id='Rectangle-Copy-2' fill='#3253EF' opacity='0.25' x='0' y='0' width='24' height='24' rx='3' />
              <g id='Icon/Discharged' transform='translate(4.000000, 4.000000)'>
                <mask id='dischargeQuestionsPath-mask-2' fill='white'>
                  <use xlinkHref='#dischargeQuestionsPath' />
                </mask>
                <g id='Mask' fillRule='nonzero' />
                <g id='Group' mask='url(#dischargeQuestionsPath-mask-2)' fill='#3253EF'>
                  <g id='↳-🎨Color'>
                    <rect id='Rectangle' x='0' y='0' width='16' height='16' />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
