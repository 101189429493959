import styled from 'styled-components';

import ChevronRight from 'svg/ChevronRight';

export const DrillableItem = styled.a<{ drillable: boolean; isRowHovered: boolean }>`
  font-weight: var(--font-weight-bold);

  ${({ drillable, isRowHovered, theme }) =>
    drillable &&
    `
  color: ${theme.colors.primaryBlue};
  cursor: pointer;

    ${isRowHovered && 'text-decoration: underline;'}
  `}
`;

export const DrillableArrow = styled(ChevronRight)`
  position: absolute;
  right: 16px;
`;
