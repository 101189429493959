import { Fragment, useMemo, useState } from 'react';
import styled from 'styled-components';

import { useQuery } from '@tanstack/react-query';
import { SortingState } from '@tanstack/react-table';

import { Select } from 'components/shared/form/InfiniteScrollDropdown';
import InputGroup, { InlineInputGroups } from 'components/shared/form/InputGroup';
import Search from 'components/shared/Search';
import ControlledTable from 'components/shared/table/ControlledTable';
import { useDebounce } from 'hooks/useDebounce';
import useIsMobile from 'hooks/useIsMobile';
import usePagination from 'hooks/usePagination';
import Attachment from 'models/Attachment';
import { DocumentTypes } from 'models/Attachment';
import { attachmentsQueryKeys, indexAttachments } from 'services/api/attachments';
import { serializeSort } from 'stores/patientFiltersStore';
import { colors } from 'styles/theme/colors';
import { H2 } from 'styles/typography';
import FolderIcon from 'svg/FolderIcon';

import columns from './tableSetup';

type Props = {
  locationEpisodeId: string;
  initialCount: number;
};

export function Files({ locationEpisodeId, initialCount }: Props) {
  const [search, setSearch] = useState('');
  const [localSort, setLocalSort] = useState<SortingState>([{ id: 'createdAt', desc: true }]);
  const debouncedSearch = useDebounce(search);

  const pagination = usePagination();

  const [filters, setFilters] = useState({
    docType: 'All',
    search: '',
  });

  const queryParams = useMemo(() => {
    return {
      locationEpisodeId,
      ...pagination.paging,
      sortBy: serializeSort(localSort),
      pageSize: 25,
      archived: false,
      ...filters,
      search: debouncedSearch,
      include: 'createdByUser,blob,fileDeletedByClient,createdByGroup',
    };
  }, [debouncedSearch, filters, locationEpisodeId, pagination.paging, localSort]);

  const { data: attachments, isPending } = useQuery({
    queryKey: attachmentsQueryKeys.index(queryParams),
    queryFn: ({ signal }) => indexAttachments(queryParams, signal),
    enabled: !!queryParams.locationEpisodeId,
  });

  const isMobile = useIsMobile();

  return (
    <PatientProfileContent>
      <FilesHeader>
        <H2>Files/Documents</H2>
      </FilesHeader>
      {initialCount ? (
        <Fragment>
          <FiltersContainer>
            <Search value={search} placeholder='Search' onChange={setSearch} />
            <InputGroup title={'Document Type'}>
              <Select<{ label: string; value: string }>
                options={['All', ...Object.values(DocumentTypes), 'None'].map((x) => ({
                  label: x,
                  value: x,
                }))}
                inputId='docTypeFilter'
                classNamePrefix='doc-type-filter'
                getOptionValue={({ value }) => value}
                getOptionLabel={({ label }) => label}
                onChange={({ value }) => {
                  return setFilters((prev) => ({ ...prev, docType: value }));
                }}
                value={filters.docType ? { label: filters.docType, value: filters.docType } : undefined}
              />
            </InputGroup>
          </FiltersContainer>
          <ControlledTable<Attachment>
            data={attachments?.data ?? []}
            columns={columns()}
            pageCount={attachments?.meta.totalPages || 1}
            loading={isPending}
            filters={filters}
            pagination={pagination}
            sorting={{ sortBy: localSort, setSortBy: setLocalSort }}
            state={{ columnVisibility: { createdAt: !isMobile, createdByUser: !isMobile, docType: !isMobile } }}
          />
        </Fragment>
      ) : (
        <EmptyStateContainer>
          <IconContainer>
            <FolderIcon width={32} height={32} color={colors.primaryBlue} />
          </IconContainer>
          <EmptyStateTitle>No Uploaded Files</EmptyStateTitle>
          <EmptyStateContent>All files uploaded for the patient will be listed here.</EmptyStateContent>
        </EmptyStateContainer>
      )}
    </PatientProfileContent>
  );
}

const PatientProfileContent = styled.div`
  width: 100%;
`;

const FilesHeader = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

const EmptyStateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #ffffff;
  margin-bottom: 25px;
  padding: 40px;
`;

const EmptyStateContent = styled.div`
  color: rgba(15, 18, 38, 0.75);
  font-size: 14px;
`;

const EmptyStateTitle = styled.div`
  font-color: rgba(15, 18, 38, 0.75);
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 8px;
`;

const IconContainer = styled.div`
  margin-bottom: 16px;
`;

const FiltersContainer = styled(InlineInputGroups)`
  & > * {
    max-width: 240px;
  }
  align-items: center;
  gap: 24px;
`;
