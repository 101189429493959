import { useRef, useState } from 'react';
import ReactDatePicker, { CalendarContainer, DatePickerProps } from 'react-datepicker';
import styled from 'styled-components';

import { InputStyles } from 'components/shared/form/Input';
import { colors } from 'styles/theme/colors';
import CalendarIcon2 from 'svg/CalendarIcon2';

type Props = Omit<DatePickerProps, 'onChange' | 'showMonthYearDropdown'> & {
  selected: Date | null;
  placeholder?: string;
  disabled?: boolean;
  $hasError?: boolean;
  clearable?: boolean;
  onCalendarClose?(): void;
  onChange: (date: Date | null) => void;

  // selectsRange and selectsMultiple are typed as `never`
  // to prevent a type error with the onChange prop
  // passed into the ReactDatePicker component
  selectsRange?: never;
  selectsMultiple?: never;
  renderFooter?: ({ dateInput }: { dateInput: React.RefObject<ReactDatePicker> }) => React.ReactNode;
};

export default function DatePicker(props: Props) {
  const dateInput = useRef<any>(null);
  const {
    disabled,
    $hasError,
    placeholder,
    clearable = true,
    selected,
    onCalendarClose,
    onChange,
    renderFooter,
    ...rest
  } = props;

  const [dateValue, setDateValue] = useState(selected);

  const onCalendarCloseHandler = () => onCalendarClose?.();
  const onChangeHandler = (date: Date | null) => {
    let outputValue = date;

    if (!clearable && !date) {
      outputValue = dateValue;
    }

    setDateValue(date);

    onChange(outputValue);
  };

  return (
    <DatePickerWrapper $hasError={$hasError ?? false} disabled={disabled ?? false}>
      <div>
        <ReactDatePicker
          {...rest}
          placeholderText={placeholder ?? 'MM/DD/YYYY'}
          disabled={disabled}
          selected={selected}
          ref={dateInput}
          popperProps={{ strategy: 'fixed' }}
          onCalendarClose={onCalendarCloseHandler}
          onChange={onChangeHandler}
          calendarClassName='olio-datepicker'
          calendarContainer={({ className, children }) => (
            <PopoverContainer>
              <CalendarContainer className={className}>
                {children}
                {renderFooter && <FooterContainer>{renderFooter({ dateInput })}</FooterContainer>}
              </CalendarContainer>
            </PopoverContainer>
          )}></ReactDatePicker>
      </div>
      <CalendarIconWrapper>
        <CalendarIconContainer onClick={() => dateInput.current?.handleFocus?.()}>
          <CalendarIcon2 width={14} height={14} color={disabled ? colors.black25 : colors.black} />
        </CalendarIconContainer>
      </CalendarIconWrapper>
    </DatePickerWrapper>
  );
}

export const DatePickerWrapper = styled.div<{
  $hasError: boolean;
  disabled: boolean;
}>`
  display: flex;
  input {
    ${InputStyles}
    width: 200px;
  }
`;

const CalendarIconWrapper = styled.div`
  position: relative;
  width: 0;
`;

const CalendarIconContainer = styled.div`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
`;

const FooterContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.black10};
  padding: 8px;
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
`;

const PopoverContainer = styled.div`
  width: 240px;
`;
