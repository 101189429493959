import { useCallback } from 'react';
import { noop } from 'lodash';

import { Dimensions } from 'components/insights/constants';
import { NONE, NONE_OPTION } from 'constants/filterKeysConstants';
import FilterValueArray from 'models/filterValues/FilterValueArray';
import { useInsightsActions, useInsightsStore } from 'stores/insightsStore';

const getCategoryId = (series: string[][], categoryName: string) =>
  categoryName === NONE ? NONE_OPTION.id : series.find((categoryValues) => categoryValues[0] === categoryName)?.[1];

const createSelectedFilter = (filterKey: string, categoryId: string, categoryName: string) => ({
  [filterKey]: new FilterValueArray([{ id: categoryId, name: categoryName }]),
});

type Props = {
  series: string[][];
  tableHeader: string;
  tableItemFilterKey: string;
  selectedDimensions: Dimensions[];
  onSelectedDimensionsChange: (selectedDimensions: Dimensions[]) => void;
  subDimensions: Dimensions[] | null;
};

const useItemDrilldown = ({
  series,
  tableHeader,
  tableItemFilterKey,
  selectedDimensions,
  onSelectedDimensionsChange,
  subDimensions,
}: Props) => {
  const { breadcrumbs, filters, selectedGroupType } = useInsightsStore();
  const { setBreadcrumbs, setSelectedFilters } = useInsightsActions();

  const handleItemDrilldown = useCallback(
    (categoryName: string) => {
      const getInitialBreadcrumbLabel = () => {
        switch (selectedDimensions[0]) {
          case Dimensions.PROVIDER_CLIENT:
            return `All ${selectedGroupType?.displayName} Companies`;
          case Dimensions.REASON:
            return `All ${tableHeader}`;
          default:
            return `All ${tableHeader}s`;
        }
      };

      const categoryId = getCategoryId(series, categoryName);

      const selectedFilter = createSelectedFilter(tableItemFilterKey, categoryId as string, categoryName);

      setSelectedFilters(selectedFilter);

      onSelectedDimensionsChange(subDimensions as Dimensions[]);

      let newBreadcrumbs = [...breadcrumbs];
      if (!newBreadcrumbs.length) {
        newBreadcrumbs = [
          {
            id: 0,
            label: getInitialBreadcrumbLabel(),
            meta: {},
          },
        ];
      }

      setBreadcrumbs([
        ...newBreadcrumbs.slice(0, -1),
        // update last breadcrumb with current filters and selected dimensions before adding new breadcrumb
        {
          ...newBreadcrumbs[newBreadcrumbs.length - 1],
          meta: { filters, selectedDimensions },
        },
        // add new breadcrumb for item being clicked into
        { id: newBreadcrumbs.length, label: categoryName, meta: {} },
      ]);
    },
    [
      series,
      tableItemFilterKey,
      breadcrumbs,
      setBreadcrumbs,
      filters,
      subDimensions,
      setSelectedFilters,
      onSelectedDimensionsChange,
      selectedDimensions,
      selectedGroupType?.displayName,
      tableHeader,
    ]
  );

  return subDimensions ? handleItemDrilldown : noop;
};

export default useItemDrilldown;
