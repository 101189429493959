import { createContext, useContext } from 'react';

import { useQuery } from '@tanstack/react-query';

import { useActingClientAuthorizedCheck } from 'components/patient/useActingClientAuthorizedCheck';
import useInvalidatedPatientQueries from 'components/patient/useInvalidatedPatientQueries';
import { useEpisodeId } from 'hooks/useEpisodeId';
import Episode from 'models/Episode';
import { episodeQueryKeys, showEpisode } from 'services/api/episode';

type EpisodeContextType = {
  episode?: Episode;
  loading: boolean;
  invalidateData: () => void;
};

const EpisodeContext = createContext<EpisodeContextType>({
  episode: {} as Episode,
  loading: true,
  invalidateData: () => {},
});

export function EpisodeProvider({ children }: { children: React.ReactNode }) {
  const episodeId = useEpisodeId();

  const invalidatePatientQueries = useInvalidatedPatientQueries();

  const episodeQueryParams = {
    id: episodeId ?? '',
    include:
      'episode_groups.group.group_type,latest_location_episode.assigned_users.credential,latest_location_episode_discharge,owner.client.enabled_flags,owner.client.enabled_provider_types,owner.group_type,transferred_from.group_type',
  };
  const authorizationChecked = useActingClientAuthorizedCheck(episodeId);

  const { data, isPending } = useQuery({
    queryKey: episodeQueryKeys.show(episodeQueryParams),
    queryFn: () => showEpisode(episodeQueryParams),
    enabled: authorizationChecked && !!episodeId,
  });

  const invalidateData = () => {
    invalidatePatientQueries({
      episode: episodeQueryParams,
    });
  };

  return <EpisodeContext value={{ episode: data, loading: isPending, invalidateData }}>{children}</EpisodeContext>;
}

export function useEpisode() {
  const context = useContext(EpisodeContext);

  if (!context) {
    throw new Error('useEpisode must be used within an EpisodeProvider');
  }

  return context;
}
