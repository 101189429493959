import styled from 'styled-components';

type Props = {
  children: React.ReactNode;
};

const MetricDetailHeader = ({ children, ...rest }: Props) => {
  return <HeaderGroup {...rest}>{children}</HeaderGroup>;
};

const HeaderGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
`;

MetricDetailHeader.Row = HeaderRow;

export default MetricDetailHeader;
