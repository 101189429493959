import styled from 'styled-components';

import { QuestionTypeConfig } from 'models/QuestionTemplate';
import colors from 'styles/theme/colors';
import { BodySmall, BodySmallBold } from 'styles/typography';

type SatisfactionQuestionType = {
  config: QuestionTypeConfig;
  value?: string;
  setValue: (v: string) => void;
  text: string;
  index?: number;
};

export default function SatisfactionScaleQuestion(props: SatisfactionQuestionType) {
  const { config, text, value, setValue, index = -1 } = props;

  const scaleButtons = [
    {
      color: '#F3C2D0',
      label: 'Very Dissatisfied',
    },
    {
      color: '#FAE7EC',
    },
    {
      color: '#FBEFCB',
      label: 'Neutral',
    },
    {
      color: '#EAF6E7',
    },
    {
      color: '#CBE8C3',
      label: 'Very Satisfied',
    },
  ];

  return (
    <QuestionContainer>
      <StyledQuestion>
        {index >= 0 && <span>{index}.</span>}
        <span>
          {text}
          {config.optional && ' (optional)'}
        </span>
      </StyledQuestion>
      <ScaleContainer>
        {scaleButtons.map(({ color, label }, index) => {
          const buttonValue = (index + 1).toString();
          const buttonAnswer = buttonValue + ' out of 5';

          return (
            <ScaleButtonGroup key={color}>
              <ScaleButton
                type='button'
                color={color}
                $selected={value === buttonAnswer}
                onClick={() => setValue(buttonAnswer)}>
                <BodySmallBold>{buttonValue}</BodySmallBold>
              </ScaleButton>
              {label && <ScaleButtonLabel>{label}</ScaleButtonLabel>}
            </ScaleButtonGroup>
          );
        })}
      </ScaleContainer>
    </QuestionContainer>
  );
}

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > *:first-child {
    flex: 1;
  }
`;

const ScaleButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  flex: 1;

  ${BodySmallBold} {
    font-size: 12px;
  }
`;

const ScaleButtonLabel = styled.p`
  width: 80%;
  font-size: 10px;
  color: ${colors.black};
  text-align: center;
`;

const ScaleButton = styled.button<{ color: string; $selected: boolean }>`
  cursor: pointer;
  height: 32px;
  width: 100%;
  border-radius: 2px;
  border: 1px solid ${({ color }) => color};
  text-align: center;
  font-size: 12px
  font-weight: bold;
  background-color: ${({ color }) => color};

  ${({ $selected }) =>
    $selected &&
    `
    border: 1px solid ${colors.primaryBlue};
    background-color: ${colors.primaryBlue10};
    color: ${colors.primaryBlue};
  `}
`;

const ScaleContainer = styled.div`
  margin: 16px 0 24px 0;
  display: flex;
  gap: 12px;
`;

const StyledQuestion = styled(BodySmall)`
  display: flex;
  gap: 8px;
`;
