import { useMemo } from 'react';

import { Dimensions, Metrics } from 'components/insights/constants';
import MetricDetailBreadcrumbs from 'components/insights/layout/details/MetricDetailBreadcrumbs';
import MetricDetailHeader from 'components/insights/layout/details/MetricDetailHeader';
import ViewBySelect from 'components/insights/layout/details/ViewBySelect';
import AnalyticsResponseData from 'components/insights/query/AnalyticsResponseData';
import AnalyticsResponseRollupCollection from 'components/insights/query/AnalyticsResponseRollupCollection';
import Histogram from 'components/shared/charts/Histogram';
import { useProfile } from 'context/profile';
import useIsMobile from 'hooks/useIsMobile';
import { useInsightsStore } from 'stores/insightsStore';

import ChartContainer from '../ChartContainer';
import { generateExtendedChartConfig } from '../helpers/oesUtils';
import MetricDetailContainer from '../MetricDetailContainer';
import MetricDiffBadge, { Comparator } from '../MetricDiffBadge';
import MetricHeaderValue from '../MetricHeaderValue';

type OlioEngagementScoreChartProps = {
  data: AnalyticsResponseData;
  loading: boolean;
  onSelectedDimensionsChange: (selectedDimensions: Dimensions[]) => void;
  rollups: AnalyticsResponseRollupCollection;
  selectedDimensions: Dimensions[];
};

const OlioEngagementScoreChart = (props: OlioEngagementScoreChartProps) => {
  const { data, loading, onSelectedDimensionsChange, rollups, selectedDimensions } = props;
  const isMobile = useIsMobile();
  const { profile } = useProfile();
  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);

  const categories = useMemo(() => data.dimensions.getDimensionValuesByIndex(0), [data?.dimensions]);
  const oesRollup = rollups.overall.metrics.getCurrentDataPoint(Metrics.OLIO_ENGAGEMENT_SCORE, 0);
  const priorOesRollup = rollups.overall.metrics.getPriorDataPoint(Metrics.OLIO_ENGAGEMENT_SCORE, 0);

  const chartConfig = useMemo(
    () =>
      generateExtendedChartConfig({
        categories,
        series: data.metrics.getSeries(Metrics.OLIO_ENGAGEMENT_SCORE),
      }),
    [categories, data]
  );

  return (
    <MetricDetailContainer
      loading={loading}
      hasData={!!categories.length}
      header={
        <MetricDetailHeader>
          <MetricDetailHeader.Row>
            <MetricHeaderValue
              label='Olio Engagement Score (OES)'
              hasData={!!categories.length}
              loading={loading}
              value={
                <>
                  {oesRollup == null ? '—' : `${oesRollup}%`}
                  <MetricDiffBadge
                    currentValue={oesRollup}
                    priorValue={priorOesRollup}
                    comparator={Comparator.GREATER_THAN}
                    formatter={(val) => `${val.toFixed()}%`}
                  />
                </>
              }
              tooltipText='Measurement of engagement activity in Olio'
            />
            {!isMobile && (
              <ViewBySelect
                selectedDimensions={selectedDimensions}
                groupType={selectedGroupType}
                onChange={(selectedOption) => {
                  onSelectedDimensionsChange(selectedOption.value);
                }}
                excludeProviderCompanyOption={!profile.canActAsManager}
              />
            )}
          </MetricDetailHeader.Row>
          {!isMobile && <MetricDetailBreadcrumbs onSelectedDimensionsChange={onSelectedDimensionsChange} />}
        </MetricDetailHeader>
      }>
      <ChartContainer>
        <Histogram config={chartConfig} />
      </ChartContainer>
    </MetricDetailContainer>
  );
};

export default OlioEngagementScoreChart;
