import { Dimensions } from 'components/insights/constants';
import { InsightsBreadcrumb } from 'components/insights/layout/details/helpers/utils';
import MetricDetailHeader from 'components/insights/layout/details/MetricDetailHeader';
import Breadcrumbs from 'components/shared/Breadcrumbs';
import { InsightsFilterState, useInsightsActions, useInsightsStore } from 'stores/insightsStore';

type Props = {
  onSelectedDimensionsChange: (selectedDimensions: Dimensions[]) => void;
};

const MetricDetailBreadcrumbs = ({ onSelectedDimensionsChange }: Props) => {
  const { breadcrumbs } = useInsightsStore();
  const { setBreadcrumbs, restoreFiltersFromSnapshot } = useInsightsActions();

  const handleBreadcrumbClick = ({ id, meta }: InsightsBreadcrumb) => {
    restoreFiltersFromSnapshot(meta.filters as InsightsFilterState);
    onSelectedDimensionsChange(meta.selectedDimensions as Dimensions[]);
    // remove all breadcrumbs if clicking the first one (id = 0)
    setBreadcrumbs(id ? breadcrumbs.slice(0, id + 1) : []);
  };

  return (
    breadcrumbs.length > 1 && (
      <MetricDetailHeader.Row>
        <Breadcrumbs breadcrumbs={breadcrumbs} onClick={handleBreadcrumbClick} />
      </MetricDetailHeader.Row>
    )
  );
};

export default MetricDetailBreadcrumbs;
