import { FilterMultiSelectProps } from 'components/shared/filters/FilterMultiSelect';
import {
  ADDITIONAL_FILTERS,
  CASE_MANAGER_DROPDOWN,
  COMANAGEMENT_TEAM,
  createProviderDropdown,
  DISCHARGE_REASON_DROPDOWN,
  EPISODE_TYPE_DROPDOWN,
  getComanagementTeamAcuteFilterDropdowns,
  LATEST_REHAB_STATE_DROPDOWN,
  PLAN_TYPE_DROPDOWN,
  UTILIZATION_MANAGER_DROPDOWN,
} from 'constants/filterConfigs';
import { ClientType } from 'models/Client';
import GroupType from 'models/GroupType';

export const getFilterSections = (
  actingClientType: ClientType,
  enabledProviderTypes: GroupType[],
  caseManagerEnabled: boolean,
  utilizationManagerEnabled: boolean
) => {
  const groupTypeFilterDropdowns = enabledProviderTypes.map((enabledProviderType: GroupType) =>
    createProviderDropdown(enabledProviderType)
  );

  const additionalFilterDropdowns: FilterMultiSelectProps[] = [
    PLAN_TYPE_DROPDOWN,
    EPISODE_TYPE_DROPDOWN,
    ...(utilizationManagerEnabled ? [UTILIZATION_MANAGER_DROPDOWN] : []),
    ...(caseManagerEnabled ? [CASE_MANAGER_DROPDOWN] : []),
    LATEST_REHAB_STATE_DROPDOWN,
    DISCHARGE_REASON_DROPDOWN,
  ];

  return [
    {
      title: COMANAGEMENT_TEAM,
      filters: [...groupTypeFilterDropdowns, ...getComanagementTeamAcuteFilterDropdowns(actingClientType)],
    },
    {
      title: ADDITIONAL_FILTERS,
      filters: additionalFilterDropdowns,
    },
  ];
};
