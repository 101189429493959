import { useMemo, useState } from 'react';

import { Dimensions, Metrics, SortOrder, Sources } from 'components/insights/constants';
import { ViewByValues } from 'components/insights/layout/details/helpers/dimensionsUtils';
import MetricBackArrow from 'components/insights/layout/details/MetricBackArrow';
import MetricDetailControlBar from 'components/insights/layout/details/MetricDetailControlBar';
import Separator from 'components/insights/layout/Separator';
import useAnalytics from 'components/insights/query/useAnalytics';

import ComparePriorPeriodToggle from '../ComparePriorPeriodToggle';

import TotalDischargesChart from './TotalDischargesChart';
import TotalDischargesDataTable from './TotalDischargesDataTable';

const TotalDischargesDetail = () => {
  const [selectedDimensions, setSelectedDimensions] = useState<Dimensions[]>(ViewByValues.reason);

  const requestParams = useMemo(
    () => ({
      source: Sources.LOCATION_EPISODE_DISCHARGES,
      dimensions: selectedDimensions,
      metrics: [Metrics.ID_COUNT],
      sortBy: `${Metrics.ID_COUNT} ${SortOrder.DESC}, ${Dimensions.GROUP_NAME} ${SortOrder.DESC}`,
      rollups: true,
    }),
    [selectedDimensions]
  );

  const analyticsResponse = useAnalytics(requestParams);

  return (
    <>
      <MetricDetailControlBar>
        <MetricBackArrow />
        <Separator height='20px' />
        <ComparePriorPeriodToggle />
      </MetricDetailControlBar>
      <TotalDischargesChart
        {...analyticsResponse}
        onSelectedDimensionsChange={setSelectedDimensions}
        selectedDimensions={selectedDimensions}
      />
      <TotalDischargesDataTable
        {...analyticsResponse}
        requestParams={requestParams}
        onSelectedDimensionsChange={setSelectedDimensions}
        selectedDimensions={selectedDimensions}
      />
    </>
  );
};

export default TotalDischargesDetail;
