import React from 'react';
import styled from 'styled-components';

import { RehabStateApiName, RehabStateState } from 'models/RehabState';
import { Label } from 'styles/typography';
import AdmissionEmptyState from 'svg/AdmissionEmptyState';
import DischargedEmptyState from 'svg/DischargedEmptyState';
import InTreatmentEmptyState from 'svg/InTreatmentEmptyState';
import QueueEmptyState from 'svg/QueueEmptyState';

const LANE_ATTRS = {
  [RehabStateApiName.Queue]: {
    component: QueueEmptyState,
    display: RehabStateState.Queue,
  },
  [RehabStateApiName.Admission]: {
    component: AdmissionEmptyState,
    display: RehabStateState.Admission,
  },
  [RehabStateApiName.InTreatment]: {
    component: InTreatmentEmptyState,
    display: RehabStateState.InTreatment,
  },
  [RehabStateApiName.Discharged]: {
    component: DischargedEmptyState,
    display: RehabStateState.Discharged,
  },
};

interface LaneEmptyStateProps {
  rehabStateApiName: RehabStateApiName;
}

function LaneEmptyState({ rehabStateApiName }: LaneEmptyStateProps) {
  const attrs = LANE_ATTRS[rehabStateApiName];
  const Component = attrs.component;

  return (
    <Container>
      {Component && <Component />}
      <StateLabel>No patients in {attrs.display}</StateLabel>
    </Container>
  );
}

export default LaneEmptyState;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const StateLabel = styled(Label)`
  color: ${({ theme }) => theme.colors.black50};
  margin-top: 14px;
`;
