import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import ActivitiesPerPatientPerWeekTile from 'components/insights/layout/tiles/ActivitiesPerPatientPerWeekTile';
import useDimensions from 'hooks/useDimensions';
import { useInsightsActions, useInsightsStore } from 'stores/insightsStore';

import { useInsightsContext } from '../InsightsContainer';

import AverageLengthOfStayTile from './tiles/AverageLengthOfStayTile';
import EscalationResponseTimeTile from './tiles/EscalationResponseTimeTile';
import OlioEngagementScoreTile from './tiles/OlioEngagementScoreTile';
import PriorityNoteResponseTimeTile from './tiles/PriorityNoteResponseTimeTile';
import ReadmissionRateTile from './tiles/ReadmissionRateTile';
import TotalDischargesTile from './tiles/TotalDischargesTile';
import MetricsGridContainer from './MetricsGridContainer';

const MetricsDashboard = () => {
  const { profile } = useInsightsContext();
  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);
  const { restoreInitialSnapshot } = useInsightsActions();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const { width } = useDimensions(wrapperRef);

  useEffect(() => {
    restoreInitialSnapshot();
  }, [restoreInitialSnapshot]);

  return (
    <MetricsDashboardWrapper ref={wrapperRef}>
      <MetricsGridContainer
        gridWidth={width}
        title={
          profile.canActAsManager
            ? `${selectedGroupType?.displayName as string} Network Performance`
            : 'Performance Overview'
        }>
        <TotalDischargesTile />
        <ReadmissionRateTile />
        <AverageLengthOfStayTile />
      </MetricsGridContainer>
      <MetricsGridContainer gridWidth={width} title='Engagement Overview'>
        {profile.canActAsManager && <ActivitiesPerPatientPerWeekTile />}
        {profile.canActAsManager && <EscalationResponseTimeTile />}
        <OlioEngagementScoreTile />
        <PriorityNoteResponseTimeTile />
      </MetricsGridContainer>
    </MetricsDashboardWrapper>
  );
};

export const MetricsDashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    margin: 0 auto 24px auto;
    max-width: 1280px;
  }
`;

export default MetricsDashboard;
