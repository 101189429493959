import { useEffect } from 'react';
import styled from 'styled-components';

import ActivityInput from 'components/shared/activityInput/ActivityInput';
import { ActivityInputValues } from 'components/shared/activityInput/useActivityInput';
import Alert from 'components/shared/Alert';
import Checkbox from 'components/shared/form/Checkbox';
import FieldLabel from 'components/shared/form/FieldLabel';
import PermissionGuard from 'components/shared/PermissionGuard';
import LocationEpisode from 'models/LocationEpisode';
import { PERMISSIONS } from 'models/Profile';
import colors from 'styles/theme/colors';
import { BodySmallBold } from 'styles/typography';
import ThumbsDownIcon from 'svg/ThumbsDownIcon';
import ThumbsUpIcon from 'svg/ThumbsUpIcon';
import UnsureIcon from 'svg/UnsureIcon';

export function worseThan(b: string, a: string): boolean {
  // For Start Treatment, latest status is an empty string
  // default to yes so that the user is prompted for
  // Unsure or No responses
  if (!a) a = 'Yes';
  return (
    ((
      {
        Yes: { Unsure: true, No: true },
        Unsure: { No: true },
      } as { [a: string]: { [b: string]: boolean } }
    )[a] ?? {})[b] ?? false
  );
}

type StatusQuestionProps = {
  value: string;
  setValue: (v: string) => void;
  text: string;
  index: number;
  latestStatus: string;
  locationEpisode: LocationEpisode;
  setInadequateExplanation?: (b: boolean) => void;
  setIsUploading?: (b: boolean) => void;
  noteValues?: ActivityInputValues;
  setNoteValues?: React.Dispatch<React.SetStateAction<ActivityInputValues>>;
};

export default function StatusQuestion(props: StatusQuestionProps) {
  const {
    text,
    value,
    index,
    noteValues,
    setNoteValues,
    setValue,
    latestStatus,
    locationEpisode,
    setInadequateExplanation,
    setIsUploading,
  } = props;
  const statusWorse = worseThan(value, latestStatus);

  useEffect(() => {
    const noteEmpty = !noteValues?.text && !noteValues?.attachments.length;
    setInadequateExplanation?.(noteEmpty && statusWorse);
  }, [setInadequateExplanation, noteValues, statusWorse]);

  return (
    <QuestionContainer $isFirst={index == 1}>
      <div>{text}</div>
      <StatusContainer>
        <StatusButton data-cy='clickable' onClick={() => setValue('Yes')} $selected={value == 'Yes'}>
          <ThumbsUpIcon color={colors.accentGreen} width={14} height={14} />
          <StatusButtonText>Yes</StatusButtonText>
        </StatusButton>
        <StatusButton data-cy='clickable' onClick={() => setValue('Unsure')} $selected={value == 'Unsure'}>
          <UnsureIcon color={colors.accentYellow} width={14} height={14} />
          <StatusButtonText>Unsure</StatusButtonText>
        </StatusButton>
        <StatusButton onClick={() => setValue('No')} $selected={value == 'No'} data-cy='clickable'>
          <ThumbsDownIcon color={colors.accentRed} width={14} height={14} />
          <StatusButtonText>No</StatusButtonText>
        </StatusButton>
      </StatusContainer>
      <FieldLabel>Please provide details regarding the patient&apos;s status</FieldLabel>
      <ActivityInput
        escalationType={noteValues?.escalationType}
        values={noteValues}
        setValues={setNoteValues || (() => {})}
        locationEpisodeId={locationEpisode.id}
        onUploading={(uploading) => setIsUploading?.(uploading)}
      />
      {statusWorse && (
        <Alert style={{ marginTop: 8 }}>
          <Alert.Text>A detailed description is required when there is a decline in status.</Alert.Text>
        </Alert>
      )}
      {value == 'No' && (
        <PermissionGuard permission={PERMISSIONS.escalationCreate}>
          <CheckboxContainer data-cy='clickable'>
            <Checkbox
              checked={noteValues?.escalated || false}
              checkedColor={colors.accentRed}
              size={14}
              labelSize='16px'
              label={'Create an escalation for the patient'}
              onChange={() => setNoteValues?.((prev) => ({ ...prev, escalated: !prev.escalated }))}
            />
          </CheckboxContainer>
        </PermissionGuard>
      )}
    </QuestionContainer>
  );
}

const QuestionContainer = styled.div<{ $isFirst: boolean }>`
  padding: 0 0 16px 0;
  ${({ $isFirst }) => !$isFirst && 'border-top: 1px solid var(--black-25); padding-top: 16px;'}
`;
const StatusContainer = styled.div`
  display: flex;
  gap: 8px;
  margin: 8px 0 24px 0;
  height: 40px;
`;
const StatusButton = styled.div<{ $selected: boolean }>`
  flex: 1;
  display: flex;
  height: 100%;
  gap: 8px;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  box-sizing: content-box;
  border-style: solid;
  border-color: ${({ $selected }) => ($selected ? 'var(--primary-blue)' : 'transparent')};
  background-color: ${({ $selected }) => ($selected ? 'var(--primary-blue-10)' : 'var(--black-05)')};
  &:hover {
    cursor: pointer;
    background-color: var(--primary-blue-10);
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex: 0;
  align-items: center;
  margin-top: 24px;
`;

const StatusButtonText = styled(BodySmallBold)`
  font-size: 14px;
`;
