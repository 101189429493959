import { createContext, useContext } from 'react';
import styled from 'styled-components';

import CircularProgress from 'components/shared/CircularProgress';
import RehabState from 'models/RehabState';
import { useRehabStatesQuery } from 'services/api/rehabStates';
import colors from 'styles/theme/colors';

type RehabStateContextType = RehabState[];

const RehabStatesContext = createContext<RehabStateContextType>([]);

export const RehabStatesProvider = ({ children }) => {
  const { data: rehabStatesData, isPending } = useRehabStatesQuery();

  if (isPending) {
    return (
      <FullScreen>
        <CircularProgress color={colors.primaryBlue} />
      </FullScreen>
    );
  }

  return <RehabStatesContext value={rehabStatesData?.data || []}>{children}</RehabStatesContext>;
};

export const useRehabStates = () => {
  const context = useContext(RehabStatesContext);
  if (!context) {
    throw new Error('useRehabStates must be used within a RehabStatesProvider');
  }

  if (context.length === 0) {
    throw new Error('No rehab states found');
  }
  return context;
};

const FullScreen = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;
