import { useMemo } from 'react';
import { isEqual } from 'lodash';
import Select from 'react-select';
import styled from 'styled-components';

import { Dimensions } from 'components/insights/constants';
import { ViewByValues } from 'components/insights/layout/details/helpers/dimensionsUtils';
import GroupType from 'models/GroupType';
import colors from 'styles/theme/colors';

type Props = {
  groupType: GroupType;
  onChange: (change) => void;
  additionalOptions?: Array<ViewByOption>;
  selectedDimensions: Dimensions[];
  excludeProviderGroupOption?: boolean;
  excludeProviderCompanyOption?: boolean;
};

type ViewByOption = {
  label: string;
  value: Dimensions[];
};

const ViewBySelect = ({
  groupType,
  onChange,
  additionalOptions,
  selectedDimensions,
  excludeProviderGroupOption = false,
  excludeProviderCompanyOption = false,
}: Props) => {
  const options = useMemo(() => {
    const providerGroupOption = {
      label: `${groupType?.displayName}`,
      value: ViewByValues.group,
    };

    const providerCompanyOption = {
      label: `${groupType?.displayName} Company`,
      value: ViewByValues.providerClient,
    };

    let options = [
      {
        label: 'Episode Type',
        value: ViewByValues.episodeClassification,
      },
      {
        label: 'Plan Type',
        value: ViewByValues.planTypeClassification,
      },
    ] as Array<ViewByOption>;

    options = [
      ...options,
      ...(excludeProviderGroupOption ? [] : [providerGroupOption]),
      ...(excludeProviderCompanyOption ? [] : [providerCompanyOption]),
      ...(additionalOptions ?? []),
    ];
    return options.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
  }, [groupType?.displayName, excludeProviderGroupOption, excludeProviderCompanyOption, additionalOptions]);

  return (
    <SelectContainer>
      <ViewBy>View By</ViewBy>
      <Select
        options={options}
        value={options.find((option) => isEqual(option.value, selectedDimensions))}
        getOptionValue={(prop) => prop.value}
        getOptionLabel={(prop) => prop.label}
        onChange={onChange}
        styles={customStyles}
        isSearchable={false}
        menuPosition='fixed'
      />
    </SelectContainer>
  );
};

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: 24,
    borderWidth: '1px',
    boxShadow: 'none',
    minHeight: 24,
    fontSize: 12,
    borderColor: colors.black25,
    '&:hover': {
      borderColor: colors.primaryBlue,
      borderWidth: '1px',
    },
    cursor: 'pointer',
    borderSize: 1,
  }),
  dropdownIndicator: (_: any) => ({
    color: colors.black,
    width: '14px',
    marginRight: '4px',
    boxSizing: 'border-box' as any,
    display: 'flex',
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: '12px',
    lineHeight: '16px',
    textOverflow: 'ellipsis',
    zIndex: 10,
  }),
  container: (provided) => ({
    ...provided,
    width: 124,
  }),
  input: (provided) => ({
    ...provided,
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: 23,
    padding: '0 8px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? colors.primaryBlue : 'inherit',
    '&:hover': { backgroundColor: state.isSelected ? colors.primaryBlue : colors.primaryBlue10 },
  }),
};
export default ViewBySelect;

const ViewBy = styled.div`
  font-size: 12px;
  margin-top: 5px;
  margin-right: 8px;
  color: var(--black)
  font-weight: normal;
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
`;
