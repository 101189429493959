import { useMemo } from 'react';
import styled from 'styled-components';

import { PHYSICIAN_TEAM } from 'constants/locationTypes';
import Episode from 'models/Episode';
import LocationEpisode from 'models/LocationEpisode';
import { AcuteLocationType, AllLocationType } from 'models/LocationType';
import { BodySmall, BodySmallBold, LabelBold } from 'styles/typography';
import AffiliateIcon from 'svg/AffiliateIcon';
import BedIcon from 'svg/BedIcon';
import HospitalIcon from 'svg/HospitalIcon';
import PayerIcon from 'svg/PayerIcon';
import PhysicianGroupIcon from 'svg/PhysicianGroupIcon';
import PhysicianIcon from 'svg/PhysicianIcon';
import StarIcon from 'svg/StarIcon';

import { episodeGroupFields } from './coManagementTeamHelpers';

type Props = {
  episode: Episode;
  locationEpisode: LocationEpisode;
};

const getLocationTypeIcon = (type: AllLocationType) => {
  switch (type) {
    case AcuteLocationType.HOSPITAL:
      return <HospitalIcon />;
    case AcuteLocationType.PAYER:
      return <PayerIcon />;
    case AcuteLocationType.PHYSICIAN_GROUP:
      return <PhysicianGroupIcon />;
    case PHYSICIAN_TEAM:
      return <PhysicianIcon />;
    default:
      return <AffiliateIcon />;
  }
};

export default function CoManagementTeam(props: Props) {
  const { episode, locationEpisode } = props;

  const postAcuteName = locationEpisode.rehabInformation.latestRehabFacility.name;
  const rehabFacilityAcronym = locationEpisode.rehabInformation.latestRehabFacilityType;

  const renderableFields = useMemo(() => episodeGroupFields(episode), [episode]);

  return (
    <TableSection>
      <TableHeader>
        <LabelBold>CO-MANAGEMENT TEAM</LabelBold>
      </TableHeader>
      <Row>
        <Column>
          <BedIcon />
          <BodySmall>{rehabFacilityAcronym}</BodySmall>
        </Column>
        <Column>
          <BodySmallBold>{postAcuteName}</BodySmallBold>
        </Column>
      </Row>
      {renderableFields.map((field) => (
        <Row key={field.id}>
          <Column>
            {getLocationTypeIcon(field.locationTypeKind)}
            <BodySmall>{field.locationTypeLabel}</BodySmall>
          </Column>
          <Column>
            <BodySmallBold>{field.name}</BodySmallBold>
            {field.isOwner && (
              <div>
                <StarIcon />
              </div>
            )}
          </Column>
        </Row>
      ))}
    </TableSection>
  );
}

const TableSection = styled.div`
  padding-bottom: 8px;
`;
const TableHeader = styled.div`
  padding: 24px;
  color: var(--black-50);
`;
const Row = styled.div`
  display: flex;
  padding: 0 24px 16px;
  gap: 24px;
`;
const Column = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 12px;

  svg {
    min-width: 14px;
  }
`;
