import { createContext, useContext } from 'react';

import Episode from 'models/Episode';
import LocationEpisode from 'models/LocationEpisode';

type PatientContextType = {
  episode?: Episode;
  locationEpisode?: LocationEpisode;
};

const PatientContext = createContext<PatientContextType>({});

interface PatientProviderProps {
  episode?: Episode;
  locationEpisode?: LocationEpisode;
  children: React.ReactNode;
}

export function PatientProvider({ episode, locationEpisode, children }: PatientProviderProps) {
  return <PatientContext value={{ episode, locationEpisode }}>{children}</PatientContext>;
}

export function usePatient() {
  const context = useContext(PatientContext);

  if (!context) {
    throw new Error('usePatient must be used within a PatientProvider');
  }

  return context;
}
