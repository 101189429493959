import { useState } from 'react';
import { noop } from 'lodash';

import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { PaginatedItems } from 'models/Paginated';
import RehabState, { RehabStateOptions } from 'models/RehabState';
import http from 'services/http';

export const rehabStatesQueryKeys = {
  index: ['rehabStates'] as const,
  infiniteIndex: ['rehabStatesInfinite'] as const,
};

export async function indexRehabStates() {
  return http.get<PaginatedItems<RehabStateOptions>>('rehab_states').then((res) => ({
    ...res.data,
    data: res.data.items.map((rehabState) => new RehabState(rehabState)).sort((a, b) => a.position - b.position),
  }));
}

export const useRehabStatesQuery = () => {
  return useQuery({
    queryKey: rehabStatesQueryKeys.index,
    queryFn: indexRehabStates,
  });
};

export const useSearchableRehabStates = () => {
  const [search, setSearch] = useState<string>('');

  // This endpoint does not implement pagination or searching
  // This allows us to filter the data on the client side based on search input
  // InfiniteQuery is used due to the required returned data structure by the FilterMultiSelect component
  const options = useInfiniteQuery({
    queryKey: rehabStatesQueryKeys.infiniteIndex,
    queryFn: indexRehabStates,
    enabled: false,
    initialPageParam: 1,
    getNextPageParam: noop,
    select: (data) => {
      const searched = {
        ...data,
        pages: data.pages.map((page) => {
          const filteredItems = page.data.filter((rehabState: RehabState) => {
            if (!search) return true;
            return rehabState.state.toLowerCase().includes(search?.toLowerCase() ?? '');
          });
          return { ...page, data: filteredItems };
        }),
      };

      return searched;
    },
  });

  return { options, onDebouncedSearch: setSearch };
};
