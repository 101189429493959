import { useRef } from 'react';

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import TagManager from '@sooro-io/react-gtm-module';

import { useEnvironment } from 'context/environment';
import { useProfile } from 'context/profile';
import Profile from 'models/Profile';

export default function AnalyticsProvider({ children }) {
  const { profile } = useProfile();
  const { environment } = useEnvironment();
  const tagManagerInitialized = useRef(false);

  const setUser = (profile: Profile) => {
    return {
      id: profile.id,
      email: profile.email,
      name: profile.name,
      clientId: profile.client?.id,
      clientName: profile.client?.name,
      clientType: profile.clientType,
      actingClientId: profile.actingClient.id,
      actingClientName: profile.actingClient.name,
      canActAsManager: profile.canActAsManager,
      canActAsProvider: profile.canActAsProvider,
      groupType: profile.groupType,
      enabledProviderTypes: profile.enabledProviderTypes?.map((p) => p.apiName),
      role: profile.role,
      lastLogin: profile.lastLogin,
    };
  };

  if (environment?.GOOGLE_TAG_MANAGER_ID && profile && !tagManagerInitialized.current) {
    TagManager.initialize({
      gtmId: environment.GOOGLE_TAG_MANAGER_ID,
      auth: environment.GOOGLE_TAG_MANAGER_AUTH,
      preview: environment.GOOGLE_TAG_MANAGER_PREVIEW,
      dataLayer: {
        event: 'profile',
        user: setUser(profile),
      },
    });
    tagManagerInitialized.current = true;
  }

  if (environment?.DATADOG_APPLICATION_ID) {
    datadogRum.init({
      applicationId: environment.DATADOG_APPLICATION_ID,
      clientToken: environment.DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: environment.DATADOG_SERVICE_NAME,
      env: environment.DATADOG_ENVIRONMENT,
      silentMultipleInit: true,
      version: environment.DATADOG_VERSION,
      sessionSampleRate: 100,
      sessionReplaySampleRate: Number(environment.DATADOG_PREMIUM_SAMPLE_RATE) || 0, //This will affect pricing
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow',
      traceSampleRate: Number(environment.DATADOG_TRACE_SAMPLE_RATE) || 0,
      ...(environment.DATADOG_TRACE_URL
        ? { allowedTracingUrls: [(url) => url.startsWith(`https://${environment.DATADOG_TRACE_URL}.olio.health/api`)] }
        : {}),
    });

    datadogRum.startSessionReplayRecording();

    const ignoredErrors = [
      'AbortError',
      'There is no interval scheduled with the given',
      'File picker already active',
      'ResizeObserver loop completed with undelivered notifications',
    ];
    datadogLogs.init({
      clientToken: environment.DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      env: environment.DATADOG_ENVIRONMENT,
      version: environment.DATADOG_VERSION,
      silentMultipleInit: true,
      service: environment.DATADOG_SERVICE_NAME,
      beforeSend: (log) => {
        //don't send logs for 0 status codes or abort errors
        if (log.http && log.http.status_code in [0]) {
          return false;
        }
        if (
          ignoredErrors.findIndex((element) =>
            [log.error?.kind, log.error?.message, log.error?.stack].some((item) => item?.includes(element))
          ) >= 0
        ) {
          return false;
        }
        return true;
      },
    });

    if (profile) {
      datadogRum.setUser(setUser(profile));
      datadogLogs.setUser(setUser(profile));
    }
  }

  return children;
}
