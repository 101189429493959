import { createContext, useContext, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import styled from 'styled-components';

import { useMutation, useQuery } from '@tanstack/react-query';

import CircularProgress from 'components/shared/CircularProgress';
import { useAuth } from 'context/auth';
import useDeepEffect from 'hooks/useDeepEffect';
import FilterValueArray from 'models/filterValues/FilterValueArray';
import FilterValueBoolean from 'models/filterValues/FilterValueBoolean';
import Profile from 'models/Profile';
import { showProfile, updateProfile } from 'services/api/profile';
import { useInsightsActions } from 'stores/insightsStore';
import { PortfolioSorts, usePortfolioActions } from 'stores/portfolioStore';
import { colors } from 'styles/theme/colors';

import { ErrorState, ErrorStateKind } from '../components/ErrorState';

type ProfileContextType = {
  profile: Profile;
  loading: boolean;
  refetch: () => void;
};

const ProfileContext = createContext<ProfileContextType>({
  profile: {} as Profile,
  loading: true,
  refetch: () => {},
});

export function ProfileProvider({ children }: { children: React.ReactNode }) {
  const { code, loading: loadingAuth, token } = useAuth();
  const [ready, setReady] = useState(false);
  const [isInitialLogin, setIsInitialLogin] = useState(false);

  const { setSelectedGroupType } = useInsightsActions();
  const { setFilters, setSorts, setSelectedTab } = usePortfolioActions();

  const { isPending: isUpdatingProfile, mutate } = useMutation({
    mutationFn: updateProfile,
    onSettled: () => setIsInitialLogin(false),
  });

  const {
    data: profile,
    error,
    isPending: isFetchingProfile,
    refetch,
    isLoadingError,
  } = useQuery({
    queryKey: ['profile'],
    queryFn: showProfile,
    retry: 3,
    retryDelay: 1000,
    enabled: ready && !(isInitialLogin && isUpdatingProfile),
  });

  useDeepEffect(() => {
    // `code` is only present in the url when redirecting back from the Auth0 login screen
    if (code) {
      setIsInitialLogin(true);
    }
  }, [code]);

  useDeepEffect(() => {
    if (isInitialLogin && !isUpdatingProfile) {
      mutate({ lastLogin: new Date().toISOString() });
    }
  }, [isInitialLogin, isUpdatingProfile, mutate]);

  const loading = isFetchingProfile || isLoadingError || loadingAuth;

  // Gives just enough time for AxiosProvider to set the token
  // on the axios instance before we start making requests
  useEffect(() => {
    if (token && !ready) {
      setReady(true);
    }
  }, [ready, token]);

  // Set insights filters on load of the user's profile. This needs to be done here so that the
  // mobile navigation has access to it if the user has yet to navigate to the insights page.
  useEffect(() => {
    if (profile?.enabledProviderTypes) {
      const { providerType } = profile?.insightsPreference?.value ?? {};

      const preferredGroupType =
        profile.enabledProviderTypes.find((pt) => pt.apiName === providerType) || profile.enabledProviderTypes[0];

      setSelectedGroupType(preferredGroupType);
    }
    // setSelectedGroupType not needed in deps array as they will not change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.enabledProviderTypes, profile?.insightsPreference]);

  // Set portfolio filters on load of the user's profile. This needs to be done here so that the
  // mobile navigation has access to it if the user has yet to navigate to the portfolio page.
  useEffect(() => {
    if (profile?.portfolioPreference) {
      const { sorts, providerType, ...initialFilters } = profile?.portfolioPreference?.value ?? {};

      const filters = Object.entries(initialFilters).reduce((acc, [key, value]) => {
        if (Array.isArray(value)) {
          acc[key] = new FilterValueArray(value);
          return acc;
        } else if (typeof value === 'boolean') {
          acc[key] = new FilterValueBoolean({ value: value, name: key });
          return acc;
        }

        return acc;
      }, {});

      setFilters(filters);
      setSorts((sorts ?? {}) as PortfolioSorts);
      if (providerType) {
        // Only set the provider type tab in the store if it's populated in the user preferences,
        // otherwise don't overwrite any simultaneous store setter triggered from
        // the Portfolio components
        setSelectedTab(providerType);
      }
    }
    // setFilters and setSorts not needed in deps array as they will not change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.portfolioPreference]);

  if ((error as AxiosError)?.response?.status === 404) {
    return <ErrorState kind={ErrorStateKind.RequestAccess} />;
  }

  if (loading) {
    return (
      <FullScreen>
        <CircularProgress color={colors.primaryBlue} />
      </FullScreen>
    );
  }

  return <ProfileContext value={{ profile, loading, refetch }}>{children}</ProfileContext>;
}

export function useProfile() {
  const context = useContext(ProfileContext);

  if (!context) {
    throw new Error('useProfile must be used within a ProfileProvider');
  }

  return context;
}

const FullScreen = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;
