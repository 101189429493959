import { useMemo } from 'react';

import { Dimensions, Metrics } from 'components/insights/constants';
import { ViewByValues } from 'components/insights/layout/details/helpers/dimensionsUtils';
import MetricDetailBreadcrumbs from 'components/insights/layout/details/MetricDetailBreadcrumbs';
import MetricDetailHeader from 'components/insights/layout/details/MetricDetailHeader';
import ViewBySelect from 'components/insights/layout/details/ViewBySelect';
import AnalyticsResponseData from 'components/insights/query/AnalyticsResponseData';
import AnalyticsResponseRollupCollection from 'components/insights/query/AnalyticsResponseRollupCollection';
import Histogram from 'components/shared/charts/Histogram';
import { useProfile } from 'context/profile';
import useIsMobile from 'hooks/useIsMobile';
import { useInsightsStore } from 'stores/insightsStore';

import ChartContainer from '../ChartContainer';
import { generateExtendedChartConfig } from '../helpers/totalDischargesUtils';
import MetricDetailContainer from '../MetricDetailContainer';
import MetricDiffBadge, { Comparator } from '../MetricDiffBadge';
import MetricHeaderValue from '../MetricHeaderValue';

type Props = {
  data: AnalyticsResponseData;
  loading: boolean;
  onSelectedDimensionsChange: (selectedDimensions: Dimensions[]) => void;
  rollups: AnalyticsResponseRollupCollection;
  selectedDimensions: Dimensions[];
};

const TotalDischargesChart = (props: Props) => {
  const { data, loading, onSelectedDimensionsChange, rollups, selectedDimensions } = props;

  const isMobile = useIsMobile();

  const { profile } = useProfile();

  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);

  const categories = useMemo(() => data.dimensions.getDimensionValuesByIndex(0), [data?.dimensions]);
  const dischargesCountRollup = rollups.overall.metrics.getCurrentDataPoint(Metrics.ID_COUNT, 0);
  const priorDischargesCountRollup = rollups.overall.metrics.getPriorDataPoint(Metrics.ID_COUNT, 0);
  const dischargesCountSeries = data.metrics.getSeries(Metrics.ID_COUNT);

  const chartConfig = useMemo(
    () => generateExtendedChartConfig({ categories, series: dischargesCountSeries }),
    [categories, dischargesCountSeries]
  );

  return (
    <MetricDetailContainer
      loading={loading}
      hasData={!!dischargesCountSeries[0]?.length}
      header={
        <MetricDetailHeader>
          <MetricDetailHeader.Row>
            <MetricHeaderValue
              label='Total Discharges'
              loading={loading}
              hasData={!!dischargesCountSeries[0]?.length}
              value={
                <>
                  {dischargesCountRollup ?? '—'}
                  <MetricDiffBadge
                    currentValue={dischargesCountRollup}
                    priorValue={priorDischargesCountRollup}
                    comparator={Comparator.GREATER_THAN}
                    formatter={(val) => `${val.toFixed()}`}
                  />
                </>
              }
            />
            {!isMobile && (
              <ViewBySelect
                selectedDimensions={selectedDimensions}
                additionalOptions={[
                  {
                    label: 'Discharged To',
                    value: ViewByValues.reason,
                  },
                ]}
                groupType={selectedGroupType}
                onChange={(selectedOption) => {
                  onSelectedDimensionsChange(selectedOption.value);
                }}
                excludeProviderCompanyOption={!profile.canActAsManager}
              />
            )}
          </MetricDetailHeader.Row>
          {!isMobile && <MetricDetailBreadcrumbs onSelectedDimensionsChange={onSelectedDimensionsChange} />}
        </MetricDetailHeader>
      }>
      <ChartContainer>
        <Histogram config={chartConfig} />
      </ChartContainer>
    </MetricDetailContainer>
  );
};

export default TotalDischargesChart;
