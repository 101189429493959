import { useCallback, useEffect, useRef } from 'react';

function usePreviousWithReset<T>(value: T) {
  const ref = useRef<T>(undefined);
  const setRef = useCallback((newValue?: T) => {
    ref.current = newValue;
  }, []);

  const reset = useCallback(() => {
    setRef(undefined);
  }, [setRef]);

  useEffect(() => {
    setRef(value);
  }, [value, setRef]);

  return [ref.current, reset];
}

function usePrevious<T>(value: T) {
  const previous = usePreviousWithReset(value)[0];

  return previous;
}

export default usePrevious;
