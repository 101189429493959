import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Badge from 'components/shared/Badge';
import CircularProgress from 'components/shared/CircularProgress';
import Tooltip from 'components/shared/Tooltip';
import { colors } from 'styles/theme/colors';
import ChartIcon from 'svg/ChartIcon';
import ChevronRight from 'svg/ChevronRight';
import InfoIconOutline from 'svg/InfoIconOutline';

type Props = {
  label: string;
  value?: string;
  tooltipText?: string;
  benchmark?: {
    value?: string;
    met?: boolean;
  };
  hasData?: boolean;
  children?: React.ReactNode;
  ref?: React.Ref<HTMLDivElement>;
  showValue?: boolean;
  loading?: boolean;
  navigateTo?: string;
};

function MetricTile(props: Props) {
  const {
    label,
    hasData = true,
    value,
    tooltipText,
    benchmark,
    children,
    ref,
    showValue = true,
    loading = false,
    navigateTo = '',
  } = props;

  const navigate = useNavigate();

  const handleTileClick = () => {
    navigate(navigateTo);
  };

  const metricValue = useMemo(() => (hasData && value && !loading ? value : <>&mdash;</>), [hasData, value, loading]);

  return (
    <Tile data-cy='insightsTile' onClick={handleTileClick} ref={ref}>
      <MetricContainer>
        <MetricLabel>
          {label}{' '}
          {!!tooltipText && (
            <Tooltip text={tooltipText} position='right'>
              <InfoIconOutline width={12} height={12} fill={colors.black50} style={{ marginLeft: 8 }} />
            </Tooltip>
          )}
          <Chevron width='5' height='9' fill={colors.black} />
        </MetricLabel>
        {showValue && <MetricValue>{metricValue}</MetricValue>}
        {loading ? (
          <SpinnerContainer>
            <CircularProgress color={colors.primaryBlue} />
          </SpinnerContainer>
        ) : (
          <MetricContent>
            {hasData ? (
              children
            ) : (
              <EmptyState>
                <ChartIcon color='#3253EF' />
                <EmptyText>No data to show for the selected date range or filters</EmptyText>
              </EmptyState>
            )}
          </MetricContent>
        )}
      </MetricContainer>

      {!!benchmark && !loading && (
        <BenchmarkContainer>
          <BenchmarkLabel>
            Benchmark: <BenchmarkValue>{benchmark?.value}</BenchmarkValue>
            {benchmark?.met !== undefined && (
              <Badge style={{ marginLeft: 8 }} color={benchmark?.met ? 'green' : 'red'}>
                {benchmark?.met ? 'Met' : 'Not Met'}
              </Badge>
            )}
          </BenchmarkLabel>
        </BenchmarkContainer>
      )}
    </Tile>
  );
}

export default MetricTile;

const EmptyText = styled.p`
  color: ${({ theme }) => theme.colors.black50};
  font-size: 12px;
  margin-top: 16px;
`;

const EmptyState = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const BenchmarkContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.black10};
  padding: 16px 24px;
`;

const MetricContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 24px 24px 16px;
`;

const BenchmarkLabel = styled.p`
  margin: 0;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.black};
`;

const BenchmarkValue = styled.span`
  font-weight: bold;
`;

const Chevron = styled(ChevronRight)`
  margin-left: auto;
  color: ${({ theme }) => theme.colors.accentGreen};
`;

const Tile = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.black10};
  border-radius: 8px;
  max-width: 412px;
  box-sizing: border-box;
  cursor: pointer;
  height: 280px;

  &:hover {
    border-color: ${({ theme }) => theme.colors.primaryBlue};
  }
`;

const MetricLabel = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  font-size: 12px;
  font-weight: normal;
`;

const MetricValue = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: 24px;
  font-weight: 900;
  margin-top: 8px;
  height: 24px;
`;

const MetricContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 16px 0 0;
`;

const SpinnerContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
