import { Dimensions } from 'components/insights/constants';
import {
  DISCHARGE_REASON,
  EPISODE_CLASSIFICATION,
  filterKeysToWords,
  PLAN_TYPE_CLASSIFICATION,
} from 'constants/filterKeysConstants';
import GroupType from 'models/GroupType';

export type ViewByKeys =
  | 'group'
  | 'owner'
  | 'provider'
  | 'providerClient'
  | 'episodeClassification'
  | 'planTypeClassification'
  | 'reason';

export const ViewByValues: Record<ViewByKeys, Dimensions[]> = {
  group: [Dimensions.GROUP_NAME],
  owner: [Dimensions.OWNER_GROUP_NAME, Dimensions.OWNER_GROUP_ID],
  provider: [Dimensions.PROVIDER_NAME],
  providerClient: [Dimensions.PROVIDER_CLIENT, Dimensions.PROVIDER_CLIENT_ID],
  episodeClassification: [Dimensions.EPISODE_CLASSIFICATION, Dimensions.EPISODE_CLASSIFICATION_ID],
  planTypeClassification: [Dimensions.PLAN_TYPE_CLASSIFICATION, Dimensions.PLAN_TYPE_CLASSIFICATION_ID],
  reason: [Dimensions.REASON, Dimensions.REASON_ID],
};

export const getInitialDimensions = (isManagerView: boolean) =>
  isManagerView ? ViewByValues.providerClient : ViewByValues.group;

export const getSubDimensions = (dimension: Dimensions, isManagerView: boolean) => {
  switch (dimension) {
    case Dimensions.PROVIDER_CLIENT:
      return ViewByValues.group;
    case Dimensions.EPISODE_CLASSIFICATION:
    case Dimensions.PLAN_TYPE_CLASSIFICATION:
    case Dimensions.REASON:
      return getInitialDimensions(isManagerView);
    default:
      return null;
  }
};

export const getTableConfig = (dimension: Dimensions, selectedGroupType: GroupType) => {
  switch (dimension) {
    case Dimensions.PLAN_TYPE_CLASSIFICATION:
      return [filterKeysToWords[PLAN_TYPE_CLASSIFICATION], PLAN_TYPE_CLASSIFICATION];
    case Dimensions.EPISODE_CLASSIFICATION:
      return [filterKeysToWords[EPISODE_CLASSIFICATION], EPISODE_CLASSIFICATION];
    case Dimensions.PROVIDER_CLIENT:
      return [`${selectedGroupType?.displayName} Company`, `${selectedGroupType.camelCaseApiName}Client`];
    case Dimensions.REASON:
      return ['Discharged To', DISCHARGE_REASON];
    default:
      return [selectedGroupType?.displayName];
  }
};

export const useTableConfig = (dimension: Dimensions, selectedGroupType: GroupType, isManagerView = true) => {
  const [tableHeader, tableItemFilterKey] = getTableConfig(dimension, selectedGroupType);

  const subDimensions = getSubDimensions(dimension, isManagerView);

  return { tableHeader, tableItemFilterKey, subDimensions };
};
