import { AnswerType } from 'components/modals/QuestionList/QuestionList';
import { QuestionKind, QuestionType } from 'models/QuestionTemplate';

export enum DischargeSteps {
  Information = 'information',
  FileRequests = 'fileRequests',
  Questions = 'questions',
  Confirm = 'confirm',
}

function removeStep(steps: DischargeSteps[], step: DischargeSteps) {
  const stepIndex = steps.findIndex((s) => s === step);
  steps.splice(stepIndex, 1);
}

export function buildDischargeFormSteps({
  hasDischargeQuestions = false,
  canContinueCare = false,
  hasAllRequestedFiles = false,
  currentStep,
}) {
  const allSteps = Object.values(DischargeSteps);

  if (hasAllRequestedFiles && currentStep === DischargeSteps.Information) {
    removeStep(allSteps, DischargeSteps.FileRequests);
  }

  if (!hasDischargeQuestions) {
    removeStep(allSteps, DischargeSteps.Questions);
  }

  if (!canContinueCare) {
    removeStep(allSteps, DischargeSteps.Confirm);
  }

  return allSteps;
}

export function buildAnsweredQuestionsFromFormValues(
  questions: QuestionType[],
  answers: Record<string, string>
): AnswerType[] {
  return questions.reduce((acc: AnswerType[], question) => {
    const answer = answers[question.id] ?? null; // this will retain the answer if it's an empty string

    if (answer === null) return acc;

    const answerWeight = findAnswerWeight(question, answer);

    acc.push({
      ...question,
      answer: {
        text: answer,
        weight: answerWeight,
      },
    });

    return acc;
  }, []);
}

function findAnswerWeight(question: QuestionType, answer: string) {
  if (question.kind === QuestionKind.YES_NO) {
    return question.config.positive?.toLowerCase() === answer.toLowerCase() ? 'positive' : 'negative';
  }

  return 'n/a';
}
