export const colors = {
  primaryBlue: '#3253ef',
  primaryBlue75: 'rgba(50, 83, 239, .75)',
  primaryBlue50: 'rgba(50, 83, 239, .5)',
  primaryBlue25: 'rgba(50, 83, 239, .25)',
  primaryBlue15: 'rgba(50, 83, 239, .15)',
  primaryBlue10: 'rgba(50, 83, 239, .1)',
  primaryBlue05: 'rgba(50, 83, 239, .05)',
  pictonBlue: '#409FF2',
  secondaryOrange: '#f5825e',
  secondaryOrange10: 'rgba(245, 130, 94, .1)',
  secondaryOrange25: 'rgba(245, 130, 94, .25)',
  secondaryOrange40: 'rgba(245, 130, 94, .4)',
  accentGreen: 'rgba(97, 189, 21, 1)',
  accentGreen10: 'rgba(97, 189, 21, .1)',
  accentYellow: '#f0c02f',
  accentRed: '#d10d44',
  accentRed10: 'rgba(209, 13, 68, .1)',
  accentRed25: 'rgba(209, 13, 68, .25)',
  accentPurple: '#6733d0',
  black: '#0f1226',
  black75: 'rgba(15, 18, 38, .75)',
  black50: 'rgba(15, 18, 38, .5)',
  black25: 'rgba(15, 18, 38, .25)',
  black10: 'rgba(15, 18, 38, .1)',
  black15: 'rgba(15, 18, 38, .15)',
  black05: 'rgba(15, 18, 38, .05)',
  black02: 'rgba(15, 18, 38, .02)',
  black03: 'rgba(15, 18, 38, .03)',
  gray: '#e2e4e6',
  white: '#ffffff',
  white90: 'rgba(255, 255, 255, .9)',
  white50: 'rgba(255, 255, 255, .5)',
  white15: 'rgba(255, 255, 255, .15)',
  lightBlueEmail: '#1fb9ec',
  hoverPrimaryBlue: '#233bab',
  hoverAccentRed: '#ab0a37',
  boxShadow: 'rgba(12,0,51,0.1)',
  scrollbarGray: '#878892',
  backgroundOrange: 'rgba(240, 192, 47, 0.25)',
  actionGreen: '#57AF0F',
};

export default colors;
