import { AFFILIATE, PHYSICIAN_TEAM } from 'constants/locationTypes';
import Episode from 'models/Episode';
import EpisodeGroup from 'models/EpisodeGroup';

export const episodeGroupFields = (episode: Episode) => {
  const managingGroups = (
    episode.episodeGroups?.map(({ group }: EpisodeGroup) => {
      const locationTypeKind = group.groupType.apiName || '';
      const locationTypeLabel = group.groupType.apiName === AFFILIATE ? 'Other' : group.groupType.displayName || '';

      if (locationTypeKind === PHYSICIAN_TEAM) {
        return null;
      }

      return {
        id: group.id,
        locationTypeKind,
        locationTypeLabel,
        name: group.name,
        isOwner: group.id === episode.owner.id,
      };
    }) || []
  ).filter((x) => !!x);

  // Add physician team attr_value info
  managingGroups.push({
    id: episode.physicianTeam?.id || '',
    locationTypeKind: PHYSICIAN_TEAM,
    locationTypeLabel: 'Physician',
    name: episode.physicianTeam?.name || '—',
    isOwner: false,
  });

  if (episode.transferredFrom) {
    managingGroups.push({
      id: episode.transferredFrom?.id,
      locationTypeKind: episode.transferredFrom?.groupType?.apiName || '',
      locationTypeLabel: episode.transferredFrom?.groupType?.displayName || '',
      name: episode.transferredFrom?.name,
      isOwner: false,
    });
  }

  // owner at the top, 'Other' last, otherwise sort alphabetically by group type name
  return managingGroups.sort((a, b) => {
    if (a.isOwner || b.locationTypeLabel === 'Other') {
      return -1;
    }

    if (b.isOwner || a.locationTypeLabel === 'Other') {
      return 1;
    }

    return a.locationTypeLabel.localeCompare(b.locationTypeLabel);
  });
};
