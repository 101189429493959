import { DividedBlurb } from 'components/Containers';
import AltcsDecision from 'components/patient/blurbs/patientOverview/AltcsDecision';
import AltcsPending from 'components/patient/blurbs/patientOverview/AltcsPending';
import CoManagementTeam from 'components/patient/blurbs/patientOverview/CoManagementTeam';
import PatientClassifications from 'components/patient/blurbs/patientOverview/PatientClassifications';
import PatientRehabState from 'components/patient/blurbs/patientOverview/PatientRehabState';
import PermissionGuard from 'components/shared/PermissionGuard';
import { Flags } from 'constants/flags';
import { SNF } from 'constants/locationTypes';
import Episode from 'models/Episode';
import LocationEpisode from 'models/LocationEpisode';
import { PERMISSIONS } from 'models/Profile';

type Props = {
  locationEpisode: LocationEpisode;
  episode: Episode;
};

export default function PatientOverview({ episode, locationEpisode }: Props) {
  const altcsApplication = locationEpisode?.altcsApplicationReview;
  const showAltcs =
    !locationEpisode?.archived &&
    locationEpisode?.owner.client.hasFlag(Flags.AltcsPending) &&
    locationEpisode?.rehabInformation.latestRehabFacilityType === SNF &&
    (locationEpisode.currentRehabState.inTreatment || locationEpisode.currentRehabState.admission);

  const patientName = episode.patient.name;

  return (
    <DividedBlurb>
      <PatientRehabState locationEpisode={locationEpisode} episode={episode} patientName={patientName} />
      <CoManagementTeam locationEpisode={locationEpisode} episode={episode} />
      <PatientClassifications locationEpisode={locationEpisode} />
      {showAltcs && (
        <PermissionGuard permission={PERMISSIONS.reviewAltcsApplicationEdit}>
          {altcsApplication ? (
            <AltcsDecision
              altcsApplication={altcsApplication}
              locationEpisode={locationEpisode}
              patientName={patientName}
            />
          ) : (
            <AltcsPending locationEpisode={locationEpisode} patientName={patientName} />
          )}
        </PermissionGuard>
      )}
    </DividedBlurb>
  );
}
