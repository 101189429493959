import { usePatient } from 'context/patient';
import { useProfile } from 'context/profile';

type PermissionGuardProps = {
  permission: string | string[];
  strictMode?: boolean;
  fallback?: React.ReactNode;
  children: React.ReactNode;
};

export default function PermissionGuard({
  permission,
  strictMode = true,
  fallback = <></>,
  children,
}: PermissionGuardProps) {
  const { profile } = useProfile();
  const patientContext = usePatient();

  const permissions = Array.isArray(permission) ? permission : [permission];

  const isPermissionGranted = strictMode
    ? profile.hasAll(permissions, patientContext)
    : profile?.hasAny(permissions, patientContext);

  return isPermissionGranted ? children : fallback;
}
