import React, { useState } from 'react';
import styled from 'styled-components';

import ConfirmServiceRefusalModal from 'components/modals/ConfirmServiceRefusalModal';
import ReassignServiceRefusal from 'components/modals/ReassignServiceRefusalModal';
import Button from 'components/shared/Button';
import ButtonGroup from 'components/shared/ButtonGroup';
import PermissionGuard from 'components/shared/PermissionGuard';
import { dateAtTimeNoRelative } from 'lib/date';
import Episode from 'models/Episode';
import LocationEpisode from 'models/LocationEpisode';
import { PERMISSIONS } from 'models/Profile';
import ServiceRefusal from 'models/reviews/ServiceRefusal';
import { colors } from 'styles/theme/colors';
import { BodySmall, Label } from 'styles/typography';
import ReassignPencilIcon from 'svg/ReassignPencilIcon';
import RefusalIcon from 'svg/RefusalIcon';
import RefuseServiceIcon from 'svg/RefuseServiceIcon';

const ServiceRefusalButton = styled(Button)`
  border-width: 1px;
`;
const Container = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.black10};
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
`;
const Header = styled.div`
  align-items: center;
  background-color: ${colors.black75};
  display: flex;
  gap: 12px;
  justify-content: center;
  padding: 11px 0px;
`;
const Body = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px 64px;
`;
const StyledBodySmall = styled(BodySmall)`
  color: ${colors.white};
`;
const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledLabel = styled(Label)`
  margin-bottom: 16px;
`;
const StyledButtonGroup = styled(ButtonGroup)`
  justify-content: center;
`;

type ServiceRefusalViewType = {
  locationEpisode: LocationEpisode;
  episode: Episode;
  patientName: string;
};

export default function ServiceRefusalView(props: ServiceRefusalViewType) {
  const { locationEpisode, episode, patientName } = props;
  const activeServiceRefusal = locationEpisode.reviews.find(
    (r): r is ServiceRefusal => r.pending && r instanceof ServiceRefusal
  ); //used to reference r.active
  const [showConfirm, setShowConfirm] = useState(false);
  const [showReassign, setShowReassign] = useState(false);

  if (!activeServiceRefusal) return null;

  return !locationEpisode.latest || locationEpisode.archived || episode.archived ? (
    <></>
  ) : (
    <Container>
      <Header>
        <RefuseServiceIcon color={colors.white} width={14} height={14} />
        <StyledBodySmall>{activeServiceRefusal.data.refusedBy?.name} is refusing services at this time</StyledBodySmall>
      </Header>
      <Body>
        <PermissionGuard
          permission={PERMISSIONS.reviewServiceRefusalEdit}
          fallback={
            <Label>
              All collaborators were notified of refused services on{' '}
              {dateAtTimeNoRelative(activeServiceRefusal.createdAt)}.
            </Label>
          }>
          <ActionsContainer>
            <StyledLabel>
              Please reassign the patient's service or confirm that services will not be used at this time.
            </StyledLabel>
            <StyledButtonGroup>
              <ServiceRefusalButton
                size='xsmall'
                data-cy='clickable'
                variant='outline-primary'
                onClick={() => setShowReassign(true)}>
                <ReassignPencilIcon />
                Reassign Services
              </ServiceRefusalButton>
              <ServiceRefusalButton
                size='xsmall'
                data-cy='clickable'
                variant='outline-danger'
                onClick={() => setShowConfirm(true)}>
                <RefusalIcon />
                Confirm Refusal
              </ServiceRefusalButton>
            </StyledButtonGroup>
          </ActionsContainer>
          {showReassign && (
            <ReassignServiceRefusal
              setShow={setShowReassign}
              patientName={patientName}
              locationEpisode={locationEpisode}
              serviceRefusal={activeServiceRefusal}
            />
          )}
          {showConfirm && (
            <ConfirmServiceRefusalModal
              setShow={setShowConfirm}
              patientName={patientName}
              locationEpisode={locationEpisode}
              serviceRefusal={activeServiceRefusal}
            />
          )}
        </PermissionGuard>
      </Body>
    </Container>
  );
}
