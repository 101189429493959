import styled from 'styled-components';

import CircularProgress from 'components/shared/CircularProgress';
import useIsMobile from 'hooks/useIsMobile';
import ExportInsights from 'svg/ExportInsights';

type Props = {
  disabled?: boolean;
  loading?: boolean;
  onClick: () => void;
  show?: boolean;
};

function Export({ disabled = false, loading, onClick, show = true, ...rest }: Props) {
  const isMobile = useIsMobile();

  const handleClick = () => {
    if (loading || disabled) return;

    onClick();
  };

  return (
    show &&
    !isMobile && (
      <Container {...rest}>
        <ExportLink disabled={disabled} onClick={handleClick}>
          {loading ? <CircularProgress size={16} thickness={2} /> : <ExportInsights />}
          <ExportText>Export</ExportText>
        </ExportLink>
      </Container>
    )
  );
}

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const ExportText = styled.div`
  padding-left: 12px;
`;
const ExportLink = styled.a<{ disabled: boolean }>`
  display: flex;
  padding-left: 6px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  font-size: 14px;
  font-weight: normal;
  opacity: ${(props) => (props.disabled ? 0.25 : 1)};
  color: ${({ theme }) => theme.colors.primaryBlue};
`;

export default Export;
