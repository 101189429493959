import { camelCase } from 'lodash';

import { parseDate } from 'lib/date';

export enum RehabStateState {
  Queue = 'Queue',
  Admission = 'Admission',
  InTreatment = 'In Treatment',
  Discharged = 'Discharged',
}

export enum RehabStateApiName {
  Queue = 'queue',
  Admission = 'admission',
  InTreatment = 'inTreatment',
  Discharged = 'discharged',
}

export interface RehabStateOptions {
  current: boolean;
  enteredAt: string | Date | null;
  id: string;
  position: number;
  state: RehabStateState;
  apiName: RehabStateApiName;
}

function getDefaults(): RehabStateOptions {
  return {
    current: true,
    enteredAt: new Date(),
    id: '',
    position: 0,
    state: RehabStateState.Queue,
    apiName: RehabStateApiName.Queue,
  };
}

/**
 * @class RehabState
 * @classdesc Represents a rehab state in the system
 * @property {boolean} current - Whether the rehab state is current
 * @property {string | Date | null} enteredAt - The rehab state's entry date
 * @property {string} id - The rehab state's id
 * @property {number} position - The rehab state's position
 * @property {RehabStateState} state - The rehab state's name
 * @property {RehabStateApiName} apiName - The rehab state's api name
 * @param {Partial<RehabStateOptions>} [options={}]
 * @example const rehabState = new RehabState({ id: '123' });
 */
export default class RehabState {
  current: boolean;
  enteredAt: Date | null;
  id: string;
  position: number;
  state: RehabStateState;
  apiName: RehabStateApiName;

  constructor(options: Partial<RehabStateOptions> = {}) {
    const opts = { ...getDefaults(), ...options };

    this.current = opts.current;
    this.enteredAt = parseDate(opts.enteredAt);
    this.id = opts.id;
    this.position = opts.position;
    this.state = opts.state;
    // The api _rehab_state.json.jbuilder view camelizes this in the response from the api
    // however, the portfolio response does not
    this.apiName = camelCase(opts.apiName) as RehabStateApiName;
  }

  get queue() {
    return this.apiName === RehabStateApiName.Queue;
  }

  get admission() {
    return this.apiName === RehabStateApiName.Admission;
  }

  get inTreatment() {
    return this.apiName === RehabStateApiName.InTreatment;
  }

  get discharged() {
    return this.apiName === RehabStateApiName.Discharged;
  }

  get name() {
    return this.state;
  }
}

export const RehabStates = {
  Queue: new RehabState({ state: RehabStateState.Queue, apiName: RehabStateApiName.Queue }),
  Admission: new RehabState({ state: RehabStateState.Admission, apiName: RehabStateApiName.Admission }),
  InTreatment: new RehabState({ state: RehabStateState.InTreatment, apiName: RehabStateApiName.InTreatment }),
  Discharged: new RehabState({ state: RehabStateState.Discharged, apiName: RehabStateApiName.Discharged }),
} as const;
