import styled from 'styled-components';

import Checkbox from 'components/shared/form/Checkbox';
import PermissionGuard from 'components/shared/PermissionGuard';
import { colors } from 'styles/theme/colors';

import { ActivityInputValues } from './useActivityInput';

type PriorityEscalationCheckboxProps = {
  permission: string;
  label: string;
  values: ActivityInputValues;
  setValues: React.Dispatch<React.SetStateAction<ActivityInputValues>>;
};

const PriorityEscalationCheckbox = ({ permission, label, values, setValues }: PriorityEscalationCheckboxProps) => {
  return (
    <PermissionGuard permission={permission}>
      <EscalateActions $darkBorder={false}>
        <Checkbox
          checked={values.escalated || false}
          checkedColor={colors.accentRed}
          size={14}
          labelSize='12px'
          label={label}
          onChange={() => setValues((prev) => ({ ...prev, escalated: !prev.escalated }))}
        />
      </EscalateActions>
    </PermissionGuard>
  );
};

export default PriorityEscalationCheckbox;

const EscalateActions = styled.div<{ $darkBorder: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  border-left: 1px solid ${({ $darkBorder }) => ($darkBorder ? 'var(--black-25)' : 'var(--black-10)')};
  padding: 4px 0px 4px 16px;
  gap: 10px;
`;
