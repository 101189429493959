import React from 'react';
import styled from 'styled-components';

import Tooltip from 'components/shared/Tooltip';
import { colors } from 'styles/theme/colors';
import InfoIconOutline from 'svg/InfoIconOutline';

type Props = {
  label: string;
  tooltipText?: string;
  loading?: boolean;
  value?: string | React.ReactNode;
  hasData: boolean;
  tooltipPosition?: 'top' | 'right' | 'bottom' | 'left';
};

const MetricHeaderValue = ({
  label,
  tooltipText = '',
  loading = false,
  value,
  tooltipPosition = 'right',
  hasData,
}: Props) => {
  return (
    <MetricContainer>
      <MetricLabel>
        {label}{' '}
        {!!tooltipText && (
          <Tooltip text={tooltipText} position={tooltipPosition}>
            <InfoIconOutline width={12} height={12} fill={colors.black50} style={{ marginLeft: 8 }} />
          </Tooltip>
        )}
      </MetricLabel>
      <MetricValue>{!loading && hasData ? value : <>&mdash;</>}</MetricValue>
    </MetricContainer>
  );
};

export default MetricHeaderValue;

const MetricContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const MetricLabel = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  font-size: 12px;
  font-weight: normal;
`;

const MetricValue = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: 24px;
  font-weight: 900;
  margin-top: 8px;
  display: flex;
  align-items: center;
`;
