import { useMemo, useState } from 'react';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import { Dimensions, Metrics } from 'components/insights/constants';
import { DrillableArrow, DrillableItem } from 'components/insights/layout/details/drillableTableComponents';
import { ActivitiesPerPatientPerWeekTableRow } from 'components/insights/layout/details/helpers/activitiesPerPatientPerWeekUtils';
import { useTableConfig } from 'components/insights/layout/details/helpers/dimensionsUtils';
import useItemDrilldown from 'components/insights/layout/details/helpers/hooks/useItemDrilldown';
import MetricDetailBreadcrumbs from 'components/insights/layout/details/MetricDetailBreadcrumbs';
import TableContainer from 'components/insights/layout/details/TableContainer';
import TableHeader from 'components/insights/layout/details/TableHeader';
import TableTitle from 'components/insights/layout/details/TableTitle';
import AnalyticsResponseData from 'components/insights/query/AnalyticsResponseData';
import AnalyticsResponseRollupCollection from 'components/insights/query/AnalyticsResponseRollupCollection';
import { AnalyticsQueryParams } from 'components/insights/query/useAnalyticsInfiniteQuery';
import DataTable from 'components/shared/DataTable';
import { useInsightsStore } from 'stores/insightsStore';

import ApiExport from '../ApiExport';
import MetricDiffBadge, { Comparator } from '../MetricDiffBadge';

type Props = {
  data: AnalyticsResponseData;
  loading: boolean;
  requestParams: AnalyticsQueryParams;
  rollups: AnalyticsResponseRollupCollection;
  onSelectedDimensionsChange: (selectedDimensions: Dimensions[]) => void;
  selectedDimensions: Dimensions[];
};

const ActivitiesPerPatientWeekDataTable = (props: Props) => {
  const { data, loading, requestParams, rollups, onSelectedDimensionsChange, selectedDimensions } = props;

  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);

  const { tableHeader, tableItemFilterKey, subDimensions } = useTableConfig(selectedDimensions[0], selectedGroupType);

  const handleItemDrilldown = useItemDrilldown({
    series: data.dimensions.series,
    tableHeader,
    tableItemFilterKey,
    selectedDimensions,
    onSelectedDimensionsChange,
    subDimensions,
  });

  const [hoveredRowId, setHoveredRowId] = useState('');

  const categories = useMemo(() => data.dimensions.getCurrentDimensionValuesByIndex(0), [data.dimensions]);

  const activitiesPerPatientPerWeekRollup = rollups.overall.metrics.getCurrentDataPoint(
    Metrics.ACTIVITIES_PER_PATIENT_PER_WEEK,
    0
  );
  const locationEpisodeIdCountRollup = rollups.overall.metrics.getCurrentDataPoint(
    Metrics.LOCATION_EPISODE_ID_COUNT,
    0
  );
  const ownerActivitiesSumRollup = rollups.overall.metrics.getCurrentDataPoint(Metrics.OWNER_ACTIVITIES_SUM, 0);

  const locationEpisodeIdCounts = data.metrics.getCurrentSeries(Metrics.LOCATION_EPISODE_ID_COUNT);
  const ownerActivitiesSums = data.metrics.getCurrentSeries(Metrics.OWNER_ACTIVITIES_SUM);
  const activitiesPerPatientPerWeek = data.metrics.getCurrentSeries(Metrics.ACTIVITIES_PER_PATIENT_PER_WEEK);
  const priorActivitiesPerPatientPerWeek = data.metrics.getPriorSeries(Metrics.ACTIVITIES_PER_PATIENT_PER_WEEK);

  const tableData = useMemo<ActivitiesPerPatientPerWeekTableRow[]>(() => {
    return categories.map((categoryName, i) => ({
      categoryName,
      activitiesPerPatientPerWeek: {
        current: activitiesPerPatientPerWeek[i],
        prior: priorActivitiesPerPatientPerWeek[i],
      },
      totalPatients: locationEpisodeIdCounts[i],
      totalActivities: ownerActivitiesSums[i],
      priorActivitiesPerPatientPerWeek: priorActivitiesPerPatientPerWeek[i],
    }));
  }, [
    activitiesPerPatientPerWeek,
    categories,
    locationEpisodeIdCounts,
    ownerActivitiesSums,
    priorActivitiesPerPatientPerWeek,
  ]);

  const columnHelper = createColumnHelper<ActivitiesPerPatientPerWeekTableRow>();

  const columns = useMemo<ColumnDef<ActivitiesPerPatientPerWeekTableRow, any>[]>(
    () => [
      columnHelper.accessor('categoryName', {
        header: tableHeader,
        cell: (cellData) => (
          <DrillableItem drillable={!!subDimensions} isRowHovered={hoveredRowId === cellData.row.id}>
            {cellData.getValue()}
          </DrillableItem>
        ),
      }),
      columnHelper.accessor('activitiesPerPatientPerWeek', {
        header: 'Activities Per Patient Per Week',
        cell: (data) => (
          <>
            <p>{data.getValue().current == null ? <>&mdash;</> : `${data.getValue().current.toFixed(1)}`}</p>{' '}
            <MetricDiffBadge
              currentValue={data.getValue().current}
              priorValue={data.getValue().prior}
              comparator={Comparator.GREATER_THAN}
              formatter={(val) => `${val.toFixed(1)}`}
            />
            {subDimensions && hoveredRowId === data.row.id && <DrillableArrow width={9} height={9} />}
          </>
        ),
        footer: () => `${activitiesPerPatientPerWeekRollup?.toFixed(1) ?? '—'} (Avg)`,
      }),
      columnHelper.accessor('totalActivities', {
        header: 'Activities',
        cell: (data) => `${data.getValue() ?? '—'}`,
        footer: () => `${ownerActivitiesSumRollup ?? '—'} (Total)`,
      }),
      columnHelper.accessor('totalPatients', {
        header: 'Patients',
        cell: (data) => `${data.getValue() ?? '—'}`,
        footer: () => `${locationEpisodeIdCountRollup ?? '—'} (Total)`,
      }),
    ],
    [
      columnHelper,
      tableHeader,
      subDimensions,
      hoveredRowId,
      activitiesPerPatientPerWeekRollup,
      ownerActivitiesSumRollup,
      locationEpisodeIdCountRollup,
    ]
  );
  const desktopOnlyColumns = ['totalActivities', 'totalPatients'];

  const defaultSort = [
    {
      id: 'totalPatients',
      desc: true,
    },
  ];

  return (
    <TableContainer>
      <TableHeader>
        <TableHeader.Row>
          <TableTitle>Activities Per Patient Per Week Overview</TableTitle>
          <ApiExport
            disabled={!tableData.length}
            show={!loading}
            params={requestParams}
            filename='activities-per-patient-per-week-export'
          />
        </TableHeader.Row>
        <MetricDetailBreadcrumbs onSelectedDimensionsChange={onSelectedDimensionsChange} />
      </TableHeader>
      <DataTable
        columns={columns}
        data={tableData}
        defaultSortBy={defaultSort}
        desktopOnlyColumns={desktopOnlyColumns}
        loading={loading}
        drillable={!!subDimensions}
        setHoveredRowId={setHoveredRowId}
        handleItemDrilldown={handleItemDrilldown}
      />
    </TableContainer>
  );
};

export default ActivitiesPerPatientWeekDataTable;
