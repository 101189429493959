import { useState } from 'react';
import styled from 'styled-components';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import Button from 'components/shared/Button';
import DatePicker from 'components/shared/form/DatePicker';
import InputGroup from 'components/shared/form/InputGroup';
import StandardModal, { Actions, PatientName } from 'components/shared/StandardModal';
import { useRehabStates } from 'context/rehabStates';
import { simpleDashDate, simpleDate } from 'lib/date';
import { snakeCase } from 'lib/util';
import LocationEpisode from 'models/LocationEpisode';
import { RehabStateApiName } from 'models/RehabState';
import { activityQueryKeys } from 'services/api/activity';
import { episodeQueryKeys } from 'services/api/episode';
import { createDateChanges } from 'services/api/locationEpisode/dateChanges';
import { locationEpisodeQueryKeys } from 'services/api/locationEpisodes';
import { BodySmall } from 'styles/typography';

export type EditDatesModalType = {
  setShow: (b: boolean) => void;
  patientName: string;
  locationEpisode: LocationEpisode;
};
export default function EditDatesModal(props: EditDatesModalType) {
  const { setShow, patientName, locationEpisode } = props;
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: createDateChanges,
  });

  const rehabStates = useRehabStates();

  const states = rehabStates.map((x) => locationEpisode.rehabStates.find((y) => y.apiName == x.apiName));
  const exists = states.map((state) => !!state);
  const oldDates = states.map((state) => {
    if (!state) return null;
    return state.enteredAt;
  });

  const [queueDate, setQueueDate] = useState<Date | null>(oldDates[0]);
  const [admissionDate, setAdmissionDate] = useState<Date | null>(oldDates[1]);
  const [treatmentDate, setTreatmentDate] = useState<Date | null>(oldDates[2]);
  const [dischargedDate, setDischargedDate] = useState<Date | null>(oldDates[3]);
  const queueError = exists[0] && !queueDate ? 'Required field' : '';
  const admissionError =
    exists[1] && !admissionDate
      ? 'Required field'
      : exists[1] && queueDate && admissionDate! < queueDate
        ? `Date must be the same date or a later date than Queue (${simpleDate(queueDate)})`
        : '';
  const treatmentError =
    exists[2] && !treatmentDate
      ? 'Required field'
      : exists[2] && admissionDate && treatmentDate! < admissionDate
        ? `Date must be the same date or a later date than Admission (${simpleDate(admissionDate)})`
        : '';
  const dischargedError =
    exists[3] && !dischargedDate
      ? 'Required field'
      : exists[3] && treatmentDate && dischargedDate! < treatmentDate
        ? `Date must be the same date or a later date than In Treatment (${simpleDate(treatmentDate)})`
        : '';

  const submitDisabled =
    !!queueError ||
    !!admissionError ||
    !!treatmentError ||
    !!dischargedError ||
    (oldDates[0] == queueDate &&
      oldDates[1] == admissionDate &&
      oldDates[2] == treatmentDate &&
      oldDates[3] == dischargedDate);

  const cancel = () => {
    setShow(false);
    setQueueDate(oldDates[0]);
    setAdmissionDate(oldDates[1]);
    setTreatmentDate(oldDates[2]);
    setDischargedDate(oldDates[3]);
  };
  const submit = () => {
    mutate(
      {
        locationEpisodeId: locationEpisode.id,
        states: [
          ...(exists[0]
            ? [
                {
                  apiName: snakeCase(RehabStateApiName.Queue),
                  enteredAt: simpleDashDate(queueDate)!,
                },
              ]
            : []),
          ...(exists[1]
            ? [
                {
                  apiName: snakeCase(RehabStateApiName.Admission),
                  enteredAt: simpleDashDate(admissionDate)!,
                },
              ]
            : []),
          ...(exists[2]
            ? [
                {
                  apiName: snakeCase(RehabStateApiName.InTreatment),
                  enteredAt: simpleDashDate(treatmentDate)!,
                },
              ]
            : []),
          ...(exists[3]
            ? [
                {
                  apiName: snakeCase(RehabStateApiName.Discharged),
                  enteredAt: simpleDashDate(dischargedDate)!,
                },
              ]
            : []),
        ],
      },
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries({ queryKey: episodeQueryKeys.show({ id: res.episodeId }) });
          queryClient.invalidateQueries({ queryKey: locationEpisodeQueryKeys.show({ id: res.id }) });
          queryClient.invalidateQueries({ queryKey: activityQueryKeys.index({ locationEpisodeId: res.id }) });
          cancel();
        },
      }
    );
  };

  return (
    <StandardModal title='Edit Dates' onCancel={cancel}>
      <PatientName>Patient: {patientName}</PatientName>
      <FormContainer>
        <InfoText>Each field must have the same date or a later date selected than the field listed above it.</InfoText>
        <InputGroup title='Queue' error={queueError}>
          <DatePicker
            $hasError={!!queueError}
            maxDate={new Date()}
            selected={queueDate}
            onChange={setQueueDate}
            disabled={!exists[0]}
          />
        </InputGroup>
        <InputGroup title='Admission' error={admissionError}>
          <DatePicker
            $hasError={!!admissionError}
            maxDate={new Date()}
            selected={admissionDate}
            onChange={setAdmissionDate}
            disabled={!exists[1]}
          />
        </InputGroup>
        <InputGroup title='In Treatment' error={treatmentError}>
          <DatePicker
            $hasError={!!treatmentError}
            maxDate={new Date()}
            selected={treatmentDate}
            onChange={setTreatmentDate}
            disabled={!exists[2]}
          />
        </InputGroup>
        <InputGroup title='Discharged' error={dischargedError}>
          <DatePicker
            $hasError={!!dischargedError}
            maxDate={new Date()}
            selected={dischargedDate}
            onChange={setDischargedDate}
            disabled={!exists[3]}
          />
        </InputGroup>
      </FormContainer>
      <Actions>
        <Button variant='ghost' onClick={cancel}>
          Cancel
        </Button>
        <Button onClick={submit} disabled={submitDisabled} loading={isPending}>
          Submit
        </Button>
      </Actions>
    </StandardModal>
  );
}

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoText = styled(BodySmall)`
  margin: 0 0 24px;
`;
