import { useMemo, useState } from 'react';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import { Dimensions, Metrics } from 'components/insights/constants';
import { DrillableArrow, DrillableItem } from 'components/insights/layout/details/drillableTableComponents';
import { getTableConfig, ViewByValues } from 'components/insights/layout/details/helpers/dimensionsUtils';
import useItemDrilldown from 'components/insights/layout/details/helpers/hooks/useItemDrilldown';
import { CategoryResponseTimeRow } from 'components/insights/layout/details/helpers/utils';
import MetricDetailBreadcrumbs from 'components/insights/layout/details/MetricDetailBreadcrumbs';
import TableContainer from 'components/insights/layout/details/TableContainer';
import TableHeader from 'components/insights/layout/details/TableHeader';
import TableTitle from 'components/insights/layout/details/TableTitle';
import AnalyticsResponseData from 'components/insights/query/AnalyticsResponseData';
import AnalyticsResponseRollupCollection from 'components/insights/query/AnalyticsResponseRollupCollection';
import { AnalyticsQueryParams } from 'components/insights/query/useAnalyticsInfiniteQuery';
import { formatDuration } from 'components/shared/charts/helpers';
import DataTable from 'components/shared/DataTable';
import { useProfile } from 'context/profile';
import { useInsightsStore } from 'stores/insightsStore';

import ApiExport from '../ApiExport';
import MetricDiffBadge, { Comparator } from '../MetricDiffBadge';

type Props = {
  data: AnalyticsResponseData;
  loading: boolean;
  rollups: AnalyticsResponseRollupCollection;
  onSelectedDimensionsChange: (selectedDimensions: Dimensions[]) => void;
  selectedDimensions: Dimensions[];
  isPriorityNote: boolean;
  responseTimeLabel: string;
  countLabel: string;
  displayHeaders: { [key: string]: string };
  requestParams: AnalyticsQueryParams;
};

const ResponseTimeDataTable = ({
  countLabel,
  data,
  displayHeaders,
  loading,
  isPriorityNote,
  requestParams,
  responseTimeLabel,
  rollups,
  onSelectedDimensionsChange,
  selectedDimensions,
}: Props) => {
  const { profile } = useProfile();

  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);

  const [hoveredRowId, setHoveredRowId] = useState('');

  const categories = useMemo(() => data.dimensions.getCurrentDimensionValuesByIndex(0), [data.dimensions]);

  // Get the metric by index because we dynamically change the metric based on business hours toggle.
  // If the requested metric order changes, this will need to change.
  const responseTimeRollup = rollups.overall.metrics.getCurrentDataPointByIndex(0, 0);
  const escalationAckIdCountRollup = rollups.overall.metrics.getCurrentDataPoint(Metrics.ID_COUNT, 0);

  const responseTimes = data.metrics.getCurrentSeriesByIndex(0);
  const priorResponseTimes = data.metrics.getPriorSeriesByIndex(0);
  const escalationAckIdCounts = data.metrics.getCurrentSeries(Metrics.ID_COUNT);

  const tableData = useMemo<CategoryResponseTimeRow[]>(() => {
    return categories.map((categoryName, i) => ({
      categoryName,
      responseTime: {
        current: responseTimes[i],
        prior: priorResponseTimes[i],
      },
      count: escalationAckIdCounts[i],
    }));
  }, [categories, escalationAckIdCounts, priorResponseTimes, responseTimes]);

  const [tableHeader, tableItemFilterKey] = useMemo(
    () =>
      isPriorityNote ? getTableConfig(selectedDimensions[0], selectedGroupType) : [displayHeaders[profile.clientType]],
    [displayHeaders, isPriorityNote, profile.clientType, selectedDimensions, selectedGroupType]
  );

  const subDimensions = useMemo(() => {
    if (!isPriorityNote || selectedDimensions[0] === Dimensions.PROVIDER_NAME) {
      return null;
    }

    if (!profile.canActAsManager || selectedDimensions[0] === Dimensions.PROVIDER_CLIENT) {
      return ViewByValues.provider;
    }

    return ViewByValues.providerClient;
  }, [isPriorityNote, profile.canActAsManager, selectedDimensions]);

  const handleItemDrilldown = useItemDrilldown({
    series: data.dimensions.series,
    tableHeader,
    tableItemFilterKey,
    selectedDimensions,
    onSelectedDimensionsChange,
    subDimensions,
  });

  const columnHelper = createColumnHelper<CategoryResponseTimeRow>();
  const columns = useMemo<ColumnDef<CategoryResponseTimeRow, any>[]>(
    () => [
      columnHelper.accessor('categoryName', {
        header: tableHeader,
        cell: (cellData) => (
          <DrillableItem drillable={!!subDimensions} isRowHovered={hoveredRowId === cellData.row.id}>
            {cellData.getValue()}
          </DrillableItem>
        ),
      }),
      columnHelper.accessor('responseTime', {
        header: responseTimeLabel,
        cell: (data) => (
          <>
            <p>{data.getValue().current == null ? <>&mdash;</> : `${formatDuration(data.getValue().current, true)}`}</p>{' '}
            <MetricDiffBadge
              currentValue={data.getValue().current}
              priorValue={data.getValue().prior}
              comparator={Comparator.LESS_THAN}
              formatter={(val) => `${formatDuration(val, true)}`}
            />
            {subDimensions && hoveredRowId === data.row.id && <DrillableArrow width={9} height={9} />}
          </>
        ),
        footer: () => `${formatDuration(responseTimeRollup, true)} (Avg)`,
      }),
      columnHelper.accessor('count', {
        header: countLabel,
        cell: (data) => data.getValue() ?? <>&mdash;</>,
        footer: () => `${escalationAckIdCountRollup} (Total)`,
      }),
    ],
    [
      columnHelper,
      tableHeader,
      responseTimeLabel,
      countLabel,
      subDimensions,
      hoveredRowId,
      responseTimeRollup,
      escalationAckIdCountRollup,
    ]
  );

  const defaultSort = [
    {
      id: 'count',
      desc: true,
    },
  ];

  const desktopOnlyColumns = ['count'];

  const filePrefix = isPriorityNote ? 'priority-response-time-export' : 'escalation-response-time-export';

  return (
    <TableContainer>
      <TableHeader>
        <TableHeader.Row>
          <TableTitle>{responseTimeLabel} Overview</TableTitle>
          <ApiExport disabled={!tableData.length} show={!loading} params={requestParams} filename={filePrefix} />
        </TableHeader.Row>
        <MetricDetailBreadcrumbs onSelectedDimensionsChange={onSelectedDimensionsChange} />
      </TableHeader>
      <DataTable
        columns={columns}
        data={tableData}
        defaultSortBy={defaultSort}
        drillable={!!subDimensions}
        setHoveredRowId={setHoveredRowId}
        handleItemDrilldown={handleItemDrilldown}
        loading={loading}
        desktopOnlyColumns={desktopOnlyColumns}
      />
    </TableContainer>
  );
};

export default ResponseTimeDataTable;
