import { DischargeFormMeta, DischargeFormValues } from 'components/dischargeForm/DischargeInformation';
import NotesQuestion from 'components/modals/QuestionList/NotesQuestion';
import SatisfactionScaleQuestion from 'components/modals/QuestionList/SatisfactionScaleQuestion';
import YesNoQuestion from 'components/modals/QuestionList/YesNoQuestion';
import { useForm } from 'context/form';
import { QuestionKind, QuestionType } from 'models/QuestionTemplate';

type Props = {
  questions: QuestionType[];
};

export default function DischargeQuestions({ questions }: Props) {
  const { values, setValue } = useForm<DischargeFormValues, DischargeFormMeta>();

  const orderedQuestions = questions.sort((a, b) => a.order - b.order);

  const handleChangeAnswer = (value: string, id: string) => {
    const newValues = { ...values.questions, [id]: value };

    setValue('questions', newValues);
  };

  return (
    <div>
      {orderedQuestions.map((question, index) => {
        const props = {
          index: index + 1,
          config: question.config,
          text: question.text,
          value: values.questions?.[question.id],
          setValue: (change) => handleChangeAnswer(change, question.id),
        };

        // React throws a warning about including the `key` prop in a spreaded props object, so
        // we still have to pass it manually for each question component
        switch (question.kind) {
          case QuestionKind.NOTES:
            return <NotesQuestion key={question.text} {...props} />;
          case QuestionKind.SATISFACTION_SCALE:
            return <SatisfactionScaleQuestion key={question.text} {...props} />;
          default:
            return <YesNoQuestion key={question.text} {...props} />;
        }
      })}
    </div>
  );
}
