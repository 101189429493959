import { useMemo } from 'react';
import styled from 'styled-components';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import { Dimensions, SortOrder, Sources } from 'components/insights/constants';
import MetricDetailBreadcrumbs from 'components/insights/layout/details/MetricDetailBreadcrumbs';
import TableHeader from 'components/insights/layout/details/TableHeader';
import TableTitle from 'components/insights/layout/details/TableTitle';
import useAnalytics from 'components/insights/query/useAnalytics';
import DataTable from 'components/shared/DataTable';
import { simpleDate } from 'lib/date';
import { Analytics } from 'services/api/insights/analytics';
import { useInsightsStore } from 'stores/insightsStore';
import { LabelBold } from 'styles/typography';

import ApiExport from '../ApiExport';
import TableContainer from '../TableContainer';

type ReadmittedPatientsTableRow = {
  patientName: string;
  episodeUrl: string;
  readmissionDate: string;
  lengthOfStay: number;
};

type Props = {
  onSelectedDimensionsChange: (selectedDimensions: Dimensions[]) => void;
};

const ReadmittedPatientsDataTable = ({ onSelectedDimensionsChange }: Props) => {
  const startDate = useInsightsStore((state) => state.filters.startDate);
  const endDate = useInsightsStore((state) => state.filters.endDate);

  const queryParams = useMemo(
    () => ({
      source: Sources.LOCATION_EPISODE_DISCHARGES,
      dimensions: [
        Dimensions.PATIENT_NAME,
        Dimensions.EPISODE_URL,
        Dimensions.READMISSION_DATE,
        Dimensions.LENGTH_OF_STAY,
      ],
      metrics: [],
      sortBy: `${Dimensions.LENGTH_OF_STAY} ${SortOrder.DESC}`,
      pageSize: 10,
    }),
    []
  );

  const analyticResponse = useAnalytics(queryParams, undefined, {
    initialPageParam: queryParams,
    getNextPageParam: (lastPage: Analytics) => lastPage?.links?.next?.payload,
  });

  const { data, query } = analyticResponse;

  const tableData = data.dimensions.currentSeries.map((row) => {
    const [patientName, episodeUrl, readmissionDate, lengthOfStay] = row;

    return {
      patientName,
      episodeUrl,
      readmissionDate,
      lengthOfStay: Number(lengthOfStay),
    };
  });

  const columnHelper = useMemo(() => createColumnHelper<ReadmittedPatientsTableRow>(), []);
  const columns = useMemo<ColumnDef<ReadmittedPatientsTableRow, any>[]>(
    () => [
      columnHelper.accessor('patientName', {
        header: 'Patient Name',
        cell: (data) => (
          <LabelBold>
            <Link target='_blank' href={data.row.original.episodeUrl}>
              {data.getValue()}
            </Link>
          </LabelBold>
        ),
      }),
      columnHelper.accessor('readmissionDate', {
        header: 'Readmission Date',
        cell: (data) => simpleDate(data.getValue()),
      }),
      columnHelper.accessor('lengthOfStay', {
        header: 'Length of Stay',
        cell: (data) => `${data.getValue()} days`,
      }),
    ],
    [columnHelper]
  );

  const desktopOnlyColumns = ['readmissionDate', 'lengthOfStay'];

  const defaultSort = [
    {
      id: 'lengthOfStay',
      desc: true,
    },
  ];

  return (
    <TableContainer>
      <TableHeader>
        <TableHeader.Row>
          <TableTitle>
            Readmitted Patients ({simpleDate(startDate as Date)} - {simpleDate(endDate as Date)})
          </TableTitle>
          <ApiExport
            disabled={!tableData.length}
            show={!query.isLoading}
            params={queryParams}
            filename='readmitted-patients-export'
          />
        </TableHeader.Row>
        <MetricDetailBreadcrumbs onSelectedDimensionsChange={onSelectedDimensionsChange} />
      </TableHeader>
      <DataTable
        columns={columns}
        data={tableData}
        defaultSortBy={defaultSort}
        desktopOnlyColumns={desktopOnlyColumns}
        hasMore={query.hasNextPage}
        loading={query.isLoading || query.isFetchingNextPage}
        onBodyEndReached={query.fetchNextPage}
      />
    </TableContainer>
  );
};

export default ReadmittedPatientsDataTable;

const Link = styled.a`
  color: var(--primary-blue);
  cursor: pointer;
  text-decoration: none;
`;
