import styled from 'styled-components';

import colors from 'styles/theme/colors';

export type Breadcrumb<Meta> = {
  id: string | number;
  label: string;
  meta: Meta;
};

type Props<Meta> = {
  breadcrumbs: Breadcrumb<Meta>[];
  onClick: (breadcrumb: Breadcrumb<Meta>) => void;
};

export default function Breadcrumbs<Meta>({ breadcrumbs, onClick }: Props<Meta>) {
  return (
    <Wrapper>
      {breadcrumbs.map((item, index) => {
        const isLastCrumb = index === breadcrumbs.length - 1;

        return (
          <LinkGroup key={item.id}>
            <Link onClick={() => !isLastCrumb && onClick(item)} isLastCrumb={isLastCrumb}>
              {item.label}
            </Link>
            {!isLastCrumb && <Separator>&gt;</Separator>}
          </LinkGroup>
        );
      })}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;
`;

const LinkGroup = styled.div`
  display: flex;
  gap: 4px;
`;

const Link = styled.a<{ isLastCrumb?: boolean }>`
  font-size: 10px;
  color: ${colors.primaryBlue};
  cursor: pointer;
  text-decoration: underline;

  ${({ isLastCrumb }) => isLastCrumb && `color: ${colors.black}; cursor: inherit; text-decoration: none;`};
`;

const Separator = styled.span`
  font-size: 10px;
`;
