import React, { useLayoutEffect, useMemo, useState } from 'react';
import { debounce, noop } from 'lodash';
import styled from 'styled-components';

import Button from 'components/shared/Button';
import FilterSlideout from 'components/shared/filters/FilterSlideout';
import FiltersCountButton from 'components/shared/FiltersCountButton';
import Foldout from 'components/shared/Foldout';
import Search from 'components/shared/Search';
import useDeepMemo from 'hooks/useDeepMemo';
import { InsightsFilterState } from 'stores/insightsStore';
import { PatientFilterState } from 'stores/patientFiltersStore';
import { PortfolioFilterState } from 'stores/portfolioStore';
import { colors } from 'styles/theme/colors';
import { H2 } from 'styles/typography';
import FilterIconAlt from 'svg/FilterIconAlt';

import MobilePageHeader from '../MobilePageHeader';
import DesktopOnly from '../responsiveness/DesktopOnly';
import MobileOnly from '../responsiveness/MobileOnly';

import { getFilterCount } from './helpers';

export type FiltersGeneric = InsightsFilterState | PatientFilterState | PortfolioFilterState;

type Props<T> = {
  applyFilters: (filters: T & { search?: string }) => void;
  filters: T;
  filterSections: any[];
  onClearFilters: () => void;
  title: string | React.ReactNode;
  callToAction?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  fetchCountThunk?: () => void;
  filterFormatter?: (filters: T) => object;
  onClick?: () => void;
  parseCount?: (data: any) => number;
  queryFn?: (params: any, signal: AbortSignal) => any;
  quickFilter?: React.ReactNode;
  quickFilterShowMinWidth?: number;
  showSearchInput?: boolean;
  showFiltersButton?: boolean;
  showResults?: boolean;
};

export default function FilterBar<T extends FiltersGeneric>(props: Props<T>) {
  const {
    applyFilters = noop,
    callToAction,
    children,
    className,
    filterSections,
    title,
    queryFn = noop,
    quickFilter,
    quickFilterShowMinWidth = 1200,
    onClearFilters = noop,
    showFiltersButton = true,
    showSearchInput = true,
    showResults = true,
  } = props;

  const shouldShowQuickFilter = (screenWidth: number) => screenWidth >= quickFilterShowMinWidth;
  const [showQuickFilter, setShowQuickFilter] = useState(shouldShowQuickFilter(window.innerWidth));
  const { search: searchFilter, ...filters } = props.filters;

  useLayoutEffect(() => {
    const handleResize = () => {
      setShowQuickFilter(shouldShowQuickFilter(window.innerWidth));
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [search, setSearch] = useState(searchFilter || '');
  const [showFilterSlideout, setShowFilterSlideout] = useState(false);

  const debouncedApplyFilters = useMemo(() => debounce(applyFilters, 500), [applyFilters]);

  const updateSearch = (value: string) => {
    setSearch(value);

    debouncedApplyFilters({ ...filters, search: value });
  };

  const filterCount = useDeepMemo(() => getFilterCount(filters as FiltersGeneric), [filters]);

  return (
    <>
      <DesktopOnly>
        <FilterBarWrapper className={className}>
          <ContentWrapper>
            <LeftContent>
              {React.isValidElement(title) ? title : <Title>{title}</Title>}
              {showSearchInput && (
                <SearchWrapper>
                  <Search
                    value={search as string}
                    placeholder='Search Patients'
                    data-cy='filterBarSearch'
                    onChange={updateSearch}
                  />
                </SearchWrapper>
              )}

              {!!quickFilter && showQuickFilter && <QuickFilterWrapper>{quickFilter}</QuickFilterWrapper>}

              {showFiltersButton && (
                <FiltersButton
                  variant='ghost'
                  size='small'
                  data-cy='filtersButton'
                  onClick={() => setShowFilterSlideout(true)}>
                  <FilterIconAlt fill={colors.primaryBlue} />
                  <span>Filters {!!filterCount && ` (${filterCount})`}</span>
                </FiltersButton>
              )}

              {!!filterCount && (
                <ClearFiltersButton variant='ghost' size='small' onClick={onClearFilters}>
                  Clear Filters
                </ClearFiltersButton>
              )}
            </LeftContent>

            <RightContent>{callToAction}</RightContent>
          </ContentWrapper>

          {children}
        </FilterBarWrapper>
      </DesktopOnly>
      <MobileOnly>
        <MobilePageHeader
          callToAction={callToAction}
          search={showSearchInput ? (search as string) : undefined}
          setSearch={updateSearch}
          title={title}
          actionElement={<FiltersCountButton filtersCount={filterCount} onClick={() => setShowFilterSlideout(true)} />}>
          {children}
        </MobilePageHeader>
      </MobileOnly>
      <Foldout open={showFilterSlideout}>
        <FilterSlideout
          data-cy='filterSlideout'
          showResults={showResults}
          handleClose={() => setShowFilterSlideout(false)}
          filters={filters as FiltersGeneric}
          filterSections={filterSections}
          queryFn={queryFn}
          resultsText='patients'
          search={search as string}
          handleApply={applyFilters}
        />
      </Foldout>
    </>
  );
}

const SearchWrapper = styled.div`
  max-width: 200px;
  min-width: 160px;
`;

const Title = styled(H2)`
  font-size: 20px;
  font-weight: var(--font-weight-bold);
  white-space: nowrap;
`;

const FilterBarWrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-wrap: wrap;
  padding: 24px;
  gap: 24px;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black10};
`;

const QuickFilterWrapper = styled.div`
  max-width: 216px;
  min-width: 160px;
  width: 100%;
`;

const BaseFiltersButton = styled(Button)`
  font-weight: normal;
  padding: 0;
`;

const ClearFiltersButton = styled(BaseFiltersButton)`
  color: ${({ theme }) => theme.colors.accentRed};
  text-decoration: underline;
`;

const FiltersButton = styled(BaseFiltersButton)`
  color: ${({ theme }) => theme.colors.primaryBlue};
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  gap: 16px;
`;

const LeftContent = styled(Content)`
  margin-right: 16px;
`;

const RightContent = styled(Content)`
  justify-content: flex-end;
`;
