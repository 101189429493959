import { createContext, useContext, useState } from 'react';
import { useBlocker } from 'react-router-dom';

import DiscardChangesModal from 'components/shared/DiscardChangesModal';

type OpenDiscardChangesModalConfig = {
  navigatingAway?: boolean;
};

type DiscardChangesContextType = {
  openDiscardChangesModal: (config?: OpenDiscardChangesModalConfig) => void;
};

const DiscardChangesContext = createContext<DiscardChangesContextType>({
  openDiscardChangesModal: () => {},
});

type Props = {
  children: React.ReactNode;
  onConfirm: () => void;
  shouldBlock: boolean;
};

export function DiscardChangesProvider({ children, onConfirm, shouldBlock }: Props) {
  const [navigatingAway, setNavigatingAway] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const openDiscardChangesModal = (config: OpenDiscardChangesModalConfig = {}) => {
    const { navigatingAway = true } = config;

    setNavigatingAway(navigatingAway);
    setShowModal(true);
  };

  const blocker = useBlocker(() => {
    if (shouldBlock) {
      openDiscardChangesModal();
    }

    return shouldBlock;
  });

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleConfirm = () => {
    if (navigatingAway) {
      blocker.proceed?.();
    }

    onConfirm(); // discard changes regardless of navigating away or staying on the page
    setShowModal(false);
  };

  return (
    <DiscardChangesContext value={{ openDiscardChangesModal }}>
      {showModal && <DiscardChangesModal onCancel={handleCancel} onConfirm={handleConfirm} />}
      {children}
    </DiscardChangesContext>
  );
}

export function useDiscardChanges() {
  const context = useContext(DiscardChangesContext);

  if (!context) {
    throw new Error('useDiscardChanges must be used within a DiscardChangesProvider');
  }

  return context;
}
