import { useMemo, useState } from 'react';
import styled from 'styled-components';

import { Dimensions, Metrics, SortOrder, Sources } from 'components/insights/constants';
import { ViewByValues } from 'components/insights/layout/details/helpers/dimensionsUtils';
import MetricBackArrow from 'components/insights/layout/details/MetricBackArrow';
import MetricDetailControlBar from 'components/insights/layout/details/MetricDetailControlBar';
import Separator from 'components/insights/layout/Separator';
import useAnalytics from 'components/insights/query/useAnalytics';
import Toggle from 'components/shared/Toggle';
import { useProfile } from 'context/profile';
import { OWNER_CLIENT_TYPES } from 'models/Client';
import { EscalationTypes } from 'models/Escalation';
import { BodySmall } from 'styles/typography';

import ComparePriorPeriodToggle from '../ComparePriorPeriodToggle';

import ResponseTimeChart from './ResponseTimeChart';
import ResponseTimeDataTable from './ResponseTimeDataTable';

type ResponseTimeDetailProps = {
  escalationType: EscalationTypes;
};

const ResponseTimeDetail = ({ escalationType }: ResponseTimeDetailProps) => {
  const { profile } = useProfile();
  const isPriorityNote = escalationType === EscalationTypes.PriorityNote;

  const [businessHoursOnly, setBusinessHoursOnly] = useState(true);
  const [selectedDimensions, setSelectedDimensions] = useState<Dimensions[]>(
    isPriorityNote
      ? profile.canActAsManager
        ? ViewByValues.providerClient
        : ViewByValues.provider
      : ViewByValues.owner
  );
  const responseTimeLabel = `${isPriorityNote ? 'Priority Note' : 'Escalation'} Response Time`;
  const countLabel = isPriorityNote ? 'Priorities' : 'Escalations';

  const metrics = useMemo(
    () => [
      ...(businessHoursOnly ? [Metrics.BUSINESS_RESPONSE_TIME_AVERAGE] : [Metrics.RESPONSE_TIME_AVERAGE]),
      Metrics.ID_COUNT,
    ],
    [businessHoursOnly]
  );

  const requestParams = useMemo(
    () => ({
      dimensions: selectedDimensions,
      metrics,
      source: Sources.ESCALATION_ACKS,
      sortBy: `${Metrics.ID_COUNT} ${SortOrder.DESC}, ${Dimensions.GROUP_NAME} ${SortOrder.DESC}`,
      rollups: true,
      escalationType: isPriorityNote ? 'priority_note' : 'escalation',
    }),
    [isPriorityNote, metrics, selectedDimensions]
  );

  const analyticsResponse = useAnalytics(requestParams);

  const displayHeaders = useMemo(
    () => ({
      [OWNER_CLIENT_TYPES.PAYOR]: 'Payer',
      [OWNER_CLIENT_TYPES.HEALTH_SYSTEM]: 'Hospital',
      [OWNER_CLIENT_TYPES.PHYSICIAN_GROUP]: 'Physician Group',
    }),
    []
  );

  return (
    <>
      <MetricDetailControlBar>
        <MetricBackArrow />
        <Separator height={'20px'} />
        <ComparePriorPeriodToggle />
        <Separator height={'20px'} />
        <ToggleContainer>
          <Toggle active={businessHoursOnly} onChange={(val) => setBusinessHoursOnly(val)} disabled={false} />
          <BodySmall>Business Hours Only</BodySmall>
        </ToggleContainer>
      </MetricDetailControlBar>
      <ResponseTimeChart
        {...analyticsResponse}
        displayHeaders={displayHeaders}
        isPriorityNote={isPriorityNote}
        onSelectedDimensionsChange={setSelectedDimensions}
        responseTimeLabel={responseTimeLabel}
        selectedDimensions={selectedDimensions}
      />
      <ResponseTimeDataTable
        {...analyticsResponse}
        countLabel={countLabel}
        displayHeaders={displayHeaders}
        isPriorityNote={isPriorityNote}
        requestParams={requestParams}
        responseTimeLabel={responseTimeLabel}
        onSelectedDimensionsChange={setSelectedDimensions}
        selectedDimensions={selectedDimensions}
      />
    </>
  );
};

export default ResponseTimeDetail;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  ${BodySmall} {
    font-size: 12px;
  }
`;
