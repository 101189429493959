import { createContext, useContext, useEffect, useRef, useState } from 'react';

import http from 'services/http';

export type Environment = {
  INTEGRATION_API_DOMAIN: string;
  OLIO_API_DOMAIN?: string;
  OLIO_FILES_DOMAIN?: string;
  UI_DOMAIN: string;
  AUTH0_ISSUER_BASE_URL: string;
  AUTH0_CLIENT_ID: string;
  AUTH0_AUDIENCE: string;
  AUTH0_COOKIE_DOMAIN?: string;
  AUTH0_OLIO_DOMAINS: string[];
  SESSION_IDLE_TIMEOUT: number;
  SESSION_RENEWAL_TIMEOUT: number;
  SESSION_LOGOUT_AFTER_IDLE: number;
  ADMIN_UI_DOMAIN: string;
  INSIGHTS_UI_DOMAIN: string;
  DATADOG_APPLICATION_ID: string;
  DATADOG_CLIENT_TOKEN: string;
  DATADOG_SERVICE_NAME: string;
  DATADOG_PREMIUM_SAMPLE_RATE: string;
  DATADOG_TRACE_SAMPLE_RATE: string;
  DATADOG_TRACE_URL: string;
  DATADOG_ENVIRONMENT: string;
  DATADOG_VERSION: string;
  GOOGLE_TAG_MANAGER_AUTH: string;
  GOOGLE_TAG_MANAGER_ID: string;
  GOOGLE_TAG_MANAGER_PREVIEW: string;
};

type EnvironmentContextType = {
  environment?: Environment;
  loading: boolean;
};

const EnvironmentContext = createContext<EnvironmentContextType>({
  environment: undefined,
  loading: true,
});

export function EnvironmentProvider({ children }: { children: React.ReactNode }) {
  const [environment, setEnvironment] = useState<Environment>();
  const [loading, setLoading] = useState(true);

  // If we reference the useState in the useEffect, it will cause it to run multiple times.
  // This value also starts off as false so we can use it to prevent multiple fetches.
  const loadingRef = useRef(false);

  useEffect(() => {
    if (environment || loadingRef.current) return;

    loadingRef.current = true;
    setLoading(true);

    http.get('/environment.json', { baseURL: '' }).then((res) => {
      const data = res.data;

      const convertedConfig = {
        ...data,
        SESSION_IDLE_TIMEOUT: parseInt(data.SESSION_IDLE_TIMEOUT),
        SESSION_RENEWAL_TIMEOUT: parseInt(data.SESSION_RENEWAL_TIMEOUT),
        SESSION_LOGOUT_AFTER_IDLE: parseInt(data.SESSION_LOGOUT_AFTER_IDLE),
        AUTH0_OLIO_DOMAINS: (data.AUTH0_OLIO_DOMAINS?.split(',') || []).filter(
          (domain: string) => new URL(domain).origin !== window.origin
        ),
      };
      setEnvironment(convertedConfig);
      loadingRef.current = false;
      setLoading(false);
    });
  }, [environment]);

  return <EnvironmentContext value={{ environment, loading }}>{children}</EnvironmentContext>;
}

export function useEnvironment() {
  const context = useContext(EnvironmentContext);

  if (!context) {
    throw new Error('useEnvironment must be used within a EnvironmentProvider');
  }

  return context;
}
