import { useMemo } from 'react';
import styled from 'styled-components';

import { Dimensions, Metrics } from 'components/insights/constants';
import MetricDetailBreadcrumbs from 'components/insights/layout/details/MetricDetailBreadcrumbs';
import MetricDetailHeader from 'components/insights/layout/details/MetricDetailHeader';
import AnalyticsResponseData from 'components/insights/query/AnalyticsResponseData';
import AnalyticsResponseRollupCollection from 'components/insights/query/AnalyticsResponseRollupCollection';
import Histogram from 'components/shared/charts/Histogram';
import { useProfile } from 'context/profile';
import useIsMobile from 'hooks/useIsMobile';
import { useInsightsStore } from 'stores/insightsStore';

import ChartContainer from '../ChartContainer';
import { generateExtendedChartConfig } from '../helpers/readmissionRateUtils';
import MetricDetailContainer from '../MetricDetailContainer';
import MetricDiffBadge, { Comparator } from '../MetricDiffBadge';
import MetricHeaderValue from '../MetricHeaderValue';
import ViewBySelect from '../ViewBySelect';

type Props = {
  data: AnalyticsResponseData;
  loading: boolean;
  onSelectedDimensionsChange: (selectedDimensions: Dimensions[]) => void;
  rollups: AnalyticsResponseRollupCollection;
  selectedDimensions: Dimensions[];
};

const ReadmissionRateChart = (props: Props) => {
  const { data, loading, onSelectedDimensionsChange, rollups, selectedDimensions } = props;
  const isMobile = useIsMobile();
  const { profile } = useProfile();
  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);

  const categories = useMemo(() => data.dimensions.getDimensionValuesByIndex(0), [data?.dimensions]);

  const readmissionsSumRollup = rollups.overall.metrics.getCurrentDataPoint(Metrics.READMISSIONS_SUM, 0);
  const readmissionRateRollup = rollups.overall.metrics.getCurrentDataPoint(Metrics.READMISSION_RATE, 0);
  const priorReadmissionRateRollup = rollups.overall.metrics.getPriorDataPoint(Metrics.READMISSION_RATE, 0);

  const chartConfig = useMemo(
    () => generateExtendedChartConfig({ categories, series: data.metrics }),
    [categories, data]
  );

  const formattedReadmissionRateRollup = readmissionRateRollup == null ? '—' : `${readmissionRateRollup.toFixed(1)}%`;

  return (
    <MetricDetailContainer
      loading={loading}
      hasData={!!categories.length}
      header={
        <MetricDetailHeader>
          <MetricDetailHeader.Row>
            <MetricHeaderValue
              label='All Cause Readmission Rate'
              loading={loading}
              hasData={!!categories.length}
              value={
                <>
                  {formattedReadmissionRateRollup}
                  <MetricDiffBadge
                    currentValue={readmissionRateRollup}
                    priorValue={priorReadmissionRateRollup}
                    comparator={Comparator.LESS_THAN}
                    formatter={(val) => `${val.toFixed(1)}%`}
                  />
                </>
              }
            />
            <Separator />
            <MetricHeaderValue
              label='All Cause Readmissions'
              loading={loading}
              value={readmissionsSumRollup ?? '—'}
              hasData={!!categories.length}
            />
            {!isMobile && (
              <ViewBySelect
                selectedDimensions={selectedDimensions}
                groupType={selectedGroupType}
                onChange={(selectedOption) => {
                  onSelectedDimensionsChange(selectedOption.value);
                }}
                excludeProviderCompanyOption={!profile.canActAsManager}
              />
            )}
          </MetricDetailHeader.Row>
          {!isMobile && <MetricDetailBreadcrumbs onSelectedDimensionsChange={onSelectedDimensionsChange} />}
        </MetricDetailHeader>
      }>
      <ChartContainer>
        <Histogram config={chartConfig} />
      </ChartContainer>
    </MetricDetailContainer>
  );
};

export default ReadmissionRateChart;

const Separator = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${({ theme }) => theme.colors.black15};
  margin: 0 24px;
`;
