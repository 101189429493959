import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useQuery } from '@tanstack/react-query';

import { useEpisodeId } from 'hooks/useEpisodeId';
import Profile from 'models/Profile';
import { escalationsQueryKeys, showEscalation, useInfiniteEscalations } from 'services/api/escalation';
import { useTaskModalActions, useTaskModalStore } from 'stores/taskModalStore';
import { colors } from 'styles/theme/colors';
import { H3, Label } from 'styles/typography';
import PriorityFlag from 'svg/PriorityFlag';
import WarnIcon from 'svg/WarnIcon';

import TaskModalBody from './TaskModalBody';
import TaskModalList from './TaskModalList';

type TaskModalType = {
  setShow: (b: boolean) => void;
  profile: Profile;
  show: boolean;
};

/**
- If episodeId is available, then show escalations for that episode only.
  - If no escalations are available for that episode, then show all escalations.
  - If escalations are available for that episode, then show only those escalations & provide an option to view all escalations.
- If episodeId is not available, then show all escalations.

Episode specific escalations cannot be shown again once "view all escalations" is clicked.
Close the modal and re-open it to view episode specific escalations.
 */
export default function TaskModal(props: TaskModalType) {
  const { setShow, profile, show } = props;

  const episodeId = useEpisodeId();
  const [showAllEscalations, setShowAllEscalations] = useState(!episodeId);
  const [selectedEscalationId, setSelectedEscalationId] = useState('');
  const [episodeHasEscalations, setEpisodeHasEscalations] = useState<boolean | null>(null);
  const patientName = useTaskModalStore((state) => state.currentPatientName);
  const viewedEpisodes = useTaskModalStore((state) => state.viewedEpisodes);
  const { addViewedEpisode } = useTaskModalActions();

  const showEpisodeEscalations = !showAllEscalations;
  const episodeViewMode = (episodeHasEscalations == null || episodeHasEscalations) && showEpisodeEscalations;

  const escalationsQuery = useInfiniteEscalations(
    {
      select: (res) => {
        if (episodeHasEscalations == null && showEpisodeEscalations) {
          setEpisodeHasEscalations(res.pages?.[0]?.meta?.totalRecords > 0);
        }

        return res;
      },
    },
    { ...(episodeViewMode ? { episode: episodeId } : {}) }
  );

  const { data: selectedEscalation, isLoading: loadingSelectedEscalation } = useQuery({
    queryKey: escalationsQueryKeys.show(selectedEscalationId),
    queryFn: () => showEscalation({ id: selectedEscalationId, include: 'blob,fileDeletedByClient' }),
    enabled: !!selectedEscalationId,
  });

  useEffect(() => {
    if (selectedEscalationId) return;

    setSelectedEscalationId(escalationsQuery.data?.pages?.[0]?.data[0]?.id ?? '');
  }, [escalationsQuery.data, selectedEscalationId]);

  const escalationTypeLabel = profile.canActAsManager ? 'Escalations' : 'Priorities';
  const taskCount = escalationsQuery.data?.pages?.[0]?.meta.totalRecords || 0;
  const handleViewAllClick = () => {
    setShowAllEscalations(true);
    setSelectedEscalationId('');
  };
  const handleOnClose = () => setShow(false);

  useEffect(() => {
    if (!profile.isAdmin && episodeId && episodeHasEscalations && !viewedEpisodes.includes(episodeId)) {
      addViewedEpisode(episodeId);
      setShow(true);
    }
  }, [addViewedEpisode, episodeId, episodeHasEscalations, profile.isAdmin, setShow, viewedEpisodes]);

  useEffect(() => {
    setShowAllEscalations(!episodeId);
    setSelectedEscalationId('');
    setEpisodeHasEscalations(null);
  }, [episodeId]);

  return (
    show && (
      <Backdrop>
        <StyledModal>
          <HeaderContainer>
            {profile.canActAsManager ? (
              <WarnIcon width={16} height={16} color={colors.accentRed} />
            ) : (
              <PriorityFlag width={16} height={16} color={colors.accentRed} />
            )}
            <H3>
              {episodeViewMode && patientName ? patientName + ' - ' : ''}
              {escalationTypeLabel}
              {taskCount ? ' (' + taskCount + ')' : ''}
            </H3>
            {episodeViewMode && taskCount > 0 && (
              <VisibilityFilter onClick={handleViewAllClick}>
                View all {escalationTypeLabel.toLowerCase()}
              </VisibilityFilter>
            )}
            <Spacer />
            <CloseButton onClick={handleOnClose}>&times;</CloseButton>
          </HeaderContainer>
          <ContentContainer data-cy='taskModalContentContainer'>
            <TaskModalList
              escalationsQuery={escalationsQuery}
              selectedEscalationId={selectedEscalationId}
              setSelectedEscalationId={setSelectedEscalationId}
              profile={profile}
            />
            <TaskModalBody
              escalation={selectedEscalation}
              loading={
                loadingSelectedEscalation || (escalationsQuery.isLoading && !escalationsQuery.isFetchingNextPage)
              }
              profile={profile}
              closeModal={handleOnClose}
            />
          </ContentContainer>
        </StyledModal>
      </Backdrop>
    )
  );
}

const CloseButton = styled.div`
  color: var(--black-75);
  font-size: 24px;
  font-weight: 400;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const Spacer = styled.div`
  flex: 1;
`;

const StyledModal = styled.div<{ overflow?: string }>`
  flex-grow: 1;
  z-index: 2;

  height: 600px;
  background-color: ${colors.white};
  flex-basis: 480px;
  max-width: 960px;
  border: 1px solid ${colors.black25};
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  margin-top: 80px;

  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 14px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black25};
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`;

const Backdrop = styled.div`
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: ${colors.black50};
  overflow: auto;
`;

const VisibilityFilter = styled(Label)`
  color: ${({ theme }) => theme.colors.primaryBlue};
  margin-left: 16px;
  cursor: pointer;
`;
