import styled from 'styled-components';

import { SectionContainer } from 'components/Containers';
import Activity from 'models/Activity';
import { BodySmall } from 'styles/typography';

enum FieldKeys {
  ADMITTED_ON = 'admitted_on',
  AFFILIATE_ID = 'affiliate_id',
  CASE_MANAGER_ID = 'case_manager_id',
  DATE = 'date',
  EPISODE_CLASSIFICATION_IDS = 'episode_classification_ids',
  GROUP_ID = 'group_id',
  HOME_HEALTH_AGENCY_ID = 'home_health_agency_id',
  HOSPITAL_ID = 'hospital_id',
  INPATIENT_REHAB_FACILITY_ID = 'inpatient_rehab_facility_id',
  LONG_TERM_ACUTE_CARE_HOSPITAL_ID = 'long_term_acute_care_hospital_id',
  OUTPATIENT_THERAPY_PROVIDER_ID = 'outpatient_therapy_provider_id',
  PAYER_CLASSIFICATION_ID = 'payer_classification_id',
  PLAN_TYPE_CLASSIFICATION_ID = 'plan_type_classification_id',
  PAYER_ID = 'payer_id',
  PHYSICIAN_GROUP_ID = 'physician_group_id',
  PHYSICIAN_TEAM_ID = 'physician_team_id',
  REHAB_FACILITY_ID = 'rehab_facility_id',
  SKILLED_NURSING_FACILITY_ID = 'skilled_nursing_facility_id',
  TRANSFERRED_FROM_ID = 'transferred_from_id',
  UTILIZATION_MANAGER_ID = 'utilization_manager_id',
}

const fieldLabels: Omit<Record<FieldKeys, string | null>, FieldKeys.GROUP_ID | FieldKeys.REHAB_FACILITY_ID> = {
  [FieldKeys.ADMITTED_ON]: 'Hospital Admission Date',
  [FieldKeys.AFFILIATE_ID]: 'Affiliate',
  [FieldKeys.CASE_MANAGER_ID]: 'Case Manager',
  [FieldKeys.DATE]: 'Projected Discharge Date',
  [FieldKeys.EPISODE_CLASSIFICATION_IDS]: 'Episode Type',
  [FieldKeys.HOME_HEALTH_AGENCY_ID]: 'Home Health Agency',
  [FieldKeys.HOSPITAL_ID]: 'Hospital',
  [FieldKeys.INPATIENT_REHAB_FACILITY_ID]: 'Inpatient Rehab Facility',
  [FieldKeys.LONG_TERM_ACUTE_CARE_HOSPITAL_ID]: 'Long Term Acute Care Hospital',
  [FieldKeys.OUTPATIENT_THERAPY_PROVIDER_ID]: 'Outpatient Therapy Provider',
  [FieldKeys.PAYER_CLASSIFICATION_ID]: 'Plan Type',
  [FieldKeys.PLAN_TYPE_CLASSIFICATION_ID]: 'Plan Type',
  [FieldKeys.PAYER_ID]: 'Payer',
  [FieldKeys.PHYSICIAN_GROUP_ID]: 'Physician Group',
  [FieldKeys.PHYSICIAN_TEAM_ID]: 'Physician Team',
  [FieldKeys.SKILLED_NURSING_FACILITY_ID]: 'Skilled Nursing Facility',
  [FieldKeys.TRANSFERRED_FROM_ID]: 'Hospital',
  [FieldKeys.UTILIZATION_MANAGER_ID]: 'Utilization Manager',
};

function formatFieldChangesKey(text: string): string {
  return text
    .replace(/_/g, ' ')
    .split(' ')
    .map((x) => x[0].toUpperCase() + x.slice(1))
    .join(' ');
}

export function FieldChangesContent(props: { activity: Activity }) {
  const { activity } = props;

  const changesText: string[] = activity.data.changes.map(
    (change: { prop: string; previous: string; current: string }) => {
      if ([FieldKeys.GROUP_ID, FieldKeys.REHAB_FACILITY_ID].includes(change.prop as FieldKeys)) {
        return `${change.previous || '—'} updated to ${change.current || '—'}`;
      } else {
        return (
          `${fieldLabels[change.prop] || formatFieldChangesKey(change.prop)} updated from ` +
          `${change.previous || '—'} to ${change.current || '—'}`
        );
      }
    }
  );

  return (
    <SectionContainer>
      {changesText?.length === 1 ? (
        <BodySmall>{changesText[0]}</BodySmall>
      ) : (
        <List>
          {changesText.map((change, i) => (
            <ListItem key={i}>{change}</ListItem>
          ))}
        </List>
      )}
    </SectionContainer>
  );
}

const List = styled.ul`
  list-style: inside;
  margin: 0px;
  padding-left: 0px;
`;

const ListItem = styled.li`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black};
`;
