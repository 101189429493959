import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { camelCase } from 'lodash';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { useMutation, useQuery } from '@tanstack/react-query';

import usePortfolioTabs from 'components/portfolio/usePortfolioTabs';
import Button from 'components/shared/Button';
import FilterChips from 'components/shared/FilterChips';
import FilterBar from 'components/shared/filters/FilterBar';
import FilterMultiSelect, { FilterMultiSelectProps } from 'components/shared/filters/FilterMultiSelect';
import DesktopOnly from 'components/shared/responsiveness/DesktopOnly';
import MobileOnly from 'components/shared/responsiveness/MobileOnly';
import { PatientState } from 'constants/filterKeysConstants';
import { useProfile } from 'context/profile';
import { useRehabStates } from 'context/rehabStates';
import useIsMobile from 'hooks/useIsMobile';
import usePrevious from 'hooks/usePrevious';
import { capitalize } from 'lib/util';
import FilterValueArray from 'models/filterValues/FilterValueArray';
import { PortfolioFilterValue } from 'models/userPreferences/PortfolioFilter';
import { indexPortfolio } from 'services/api/portfolio';
import { upsertPortfolioFilter } from 'services/api/preferences';
import { usePortfolioActions, usePortfolioStore } from 'stores/portfolioStore';
import { FilterOption } from 'stores/types';
import colors from 'styles/theme/colors';
import PlusIcon from 'svg/PlusIcon';

import OlioFooter from '../shared/OlioFooter';
import Tabs, { TabType } from '../Tabs';

import { filtersForSelectedTab, getFilterSections } from './helpers/filters';
import { PortfolioLane } from './PortfolioLane';

export type PortfolioTab = {
  value: string;
  displayName: string;
  locationType?: string;
  groupType?: string;
  patientState?: PatientState;
  count?: number;
  label?: string;
};

export default function PortfolioPage() {
  const { profile } = useProfile();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [scrolledTab, setScrolledTab] = useState<TabType>();
  const [selectedTab, setSelectedTab] = useState<PortfolioTab>();
  const previousTab = usePrevious(selectedTab);

  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsTab = searchParams.get('tab');

  const selectedTabInStore = usePortfolioStore((state) => state.selectedTab);
  const filters = usePortfolioStore((state) => state.filters);
  const search = usePortfolioStore((state) => state.search);
  const sorts = usePortfolioStore((state) => state.sorts);

  const tabs = usePortfolioTabs(search);
  const tabFromSearchParams = useMemo(() => tabs.find((x) => x.value === searchParamsTab), [searchParamsTab, tabs]);
  const tabFromStore = useMemo(() => tabs.find((x) => x.value === selectedTabInStore), [selectedTabInStore, tabs]);

  const {
    setSelectedTab: setSelectedTabInStore,
    setSearch,
    setFilters,
    setFilter,
    removeFilter,
  } = usePortfolioActions();

  const lanesWrapperRef = useRef<HTMLDivElement>(null);

  const laneRefs = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
  ];

  const filtersForSelectedGroupType = useMemo(() => {
    if (!selectedTab) return {};

    return filtersForSelectedTab(filters, selectedTab);
  }, [filters, selectedTab]);

  const caseManagerEnabledForSelectedGroupType = !!profile?.actingClient.configForGroupType(selectedTab?.value ?? '')
    ?.caseManager;
  const utilManagerEnabledForSelectedGroupType = !!profile?.actingClient.configForGroupType(selectedTab?.value ?? '')
    ?.utilizationManager;

  const filterSections = useMemo(() => {
    if (!selectedTab) return [];

    return getFilterSections(
      selectedTab,
      profile.actingClient?.clientType,
      caseManagerEnabledForSelectedGroupType,
      utilManagerEnabledForSelectedGroupType
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, profile.actingClientId]);

  const enabledProviderFilterName = camelCase(selectedTab?.value ?? '');

  const currentTabFilter = useMemo(
    () => ({
      locationType: selectedTab?.locationType,
      patientState: selectedTab?.patientState,
    }),
    [selectedTab]
  );

  const basePortfolioParams = useMemo(
    () => ({
      locationType: currentTabFilter.locationType,
      patientState: currentTabFilter.patientState,
    }),
    [currentTabFilter]
  );

  const portfolioParams = useMemo(() => {
    return {
      ...basePortfolioParams,
      filters: filtersForSelectedGroupType,
      currentRehabStateApiName: 'all',
      search,
      sortBy: Object.values(sorts)
        .map((lane) => `${lane.key}.${lane.attributeName} ${lane.direction}`)
        .join(','),
    };

    // Do not include `sorts` in dependency array as when the initial sort changes, the PortfolioLane handles the query
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePortfolioParams, filtersForSelectedGroupType, search]);

  const { data: portfolio, isLoading: loadingPortfolio } = useQuery({
    queryKey: ['portfolio', profile.actingClientId, { ...portfolioParams }],
    queryFn: ({ signal }) => {
      return indexPortfolio(portfolioParams, signal) as any;
    },
    // Don't fetch portfolio data until tab is selected and either all state values match or
    // neither a valid tab is specified in the url nor a valid preferred tab exists
    enabled:
      !!selectedTab &&
      (selectedTab.value === selectedTabInStore || (!tabFromSearchParams?.value && !tabFromStore?.value)),
  });

  const slideOutCountQuery = useCallback(
    (params, signal) => {
      return indexPortfolio({ ...params, ...basePortfolioParams }, signal);
    },
    [basePortfolioParams]
  );

  const rehabStates = useRehabStates();

  const mobileTabs = useMemo(() => {
    return rehabStates.map((rehabState) => {
      const laneCount = portfolio?.[rehabState.apiName]?.meta?.totalRecords ?? 0;
      return {
        label: laneCount !== undefined ? `${rehabState.state} (${laneCount})` : rehabState.state,
        value: rehabState.apiName,
      } as TabType;
    });
  }, [portfolio, rehabStates]);

  const { mutate } = useMutation({
    mutationFn: upsertPortfolioFilter,
  });

  const handleChangeProviders = async (changes: FilterValueArray) => {
    setFilter(enabledProviderFilterName, changes);

    const updatedFilters = { ...filters };
    updatedFilters[enabledProviderFilterName] = changes;

    mutate({
      clientId: profile.actingClient.id,
      value: {
        ...updatedFilters,
        sorts,
        providerType: selectedTabInStore || selectedTab?.value,
      } as PortfolioFilterValue,
    });
  };

  const handleApplyFilters = async ({ search, ...updatedFilters }) => {
    const newFilters = { ...filters, ...updatedFilters };
    setFilters(newFilters);
    setSearch(search);
    mutate({
      clientId: profile.actingClient.id as string,
      value: { ...newFilters, sorts, providerType: selectedTabInStore || selectedTab?.value } as PortfolioFilterValue,
    });
  };

  const handleClearFilters = async () => {
    setFilters({});
    mutate({
      clientId: profile.actingClient.id,
      value: { sorts, providerType: selectedTabInStore || selectedTab?.value } as PortfolioFilterValue,
    });
  };

  const handleRemoveFilter = async (filterKey: string, id: string) => {
    removeFilter(filterKey, id);

    const updatedFilters = { ...filters };

    updatedFilters[filterKey] = updatedFilters[filterKey].removeFilter(id);

    mutate({
      clientId: profile.actingClient.id,
      value: {
        ...updatedFilters,
        sorts,
        providerType: selectedTabInStore || selectedTab?.value,
      } as PortfolioFilterValue,
    });
  };

  const handleTabClick = (tab: TabType) => {
    setSearchParams({ tab: tab.value });
  };

  const handleMobileTabClick = (tab: TabType) => {
    const laneRef = laneRefs[mobileTabs.indexOf(tab)].current;
    if (!laneRef || !lanesWrapperRef.current) return;
    lanesWrapperRef.current.scrollTo({ left: laneRef?.offsetLeft, behavior: 'smooth' });
  };

  const changeSelectedTab = useCallback(
    (tab: PortfolioTab) => {
      setSelectedTab(tab);
      setSelectedTabInStore(tab.value);
    },
    [setSelectedTabInStore]
  );

  useLayoutEffect(() => {
    if (tabFromSearchParams?.value) {
      // Always defer to the tab specified in the url if valid
      if (tabFromSearchParams?.value === selectedTab?.value) return;
      changeSelectedTab(tabFromSearchParams);
    } else if (tabFromStore?.value) {
      // Otherwise, if the user has a valid preference that has been
      // loaded into the store, update the tab in the url
      setSearchParams({ tab: tabFromStore?.value });
    } else {
      // Otherwise, a valid preference is still loading or doesn't exist,
      // so default to showing the first tab until if/when the preference
      // is populated in the store
      setSelectedTab(tabs[0]);
    }
  }, [selectedTab, tabFromSearchParams, tabFromStore?.value, tabs, changeSelectedTab, setSearchParams]);

  useEffect(() => {
    if (previousTab && selectedTabInStore && (previousTab as PortfolioTab).value !== selectedTabInStore) {
      // Only save the tab to the backend if it differs from the previous tab, whether that was
      // a saved preference or the default tab
      mutate({
        clientId: profile.actingClient.id,
        value: { ...filters, sorts, providerType: selectedTabInStore } as PortfolioFilterValue,
      });
    }
    // Excluding items from array to avoid duplicate mutate calls
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.actingClient.id, mutate, selectedTabInStore]);

  return (
    <Portfolio>
      <FilterBar
        title={isMobile ? `${capitalize(selectedTab?.displayName ?? '')} Patients` : 'Patient Portfolio'}
        filters={{ ...filtersForSelectedGroupType, search }}
        filterSections={filterSections}
        queryFn={slideOutCountQuery}
        quickFilterShowMinWidth={1100}
        applyFilters={handleApplyFilters}
        onClearFilters={handleClearFilters}
        quickFilter={
          selectedTab?.locationType && (
            <FilterMultiSelect
              {...(filterSections[0].filters[0] as FilterMultiSelectProps)}
              onChange={handleChangeProviders}
              value={filters[camelCase(enabledProviderFilterName)] as FilterOption[]}
            />
          )
        }
        callToAction={
          isMobile
            ? null
            : profile.canCreatePatient && (
                <AddPatientButton onClick={() => navigate('/patients/new')}>
                  <PlusIcon color={colors.white} width={14} height={14} />
                  Add Patient
                </AddPatientButton>
              )
        }>
        <MobileOnly>
          <TabFiltersWrapper>
            <Tabs tabs={mobileTabs} onTabClick={handleMobileTabClick} selectedTab={scrolledTab ?? mobileTabs[0]} />
          </TabFiltersWrapper>
        </MobileOnly>
        <DesktopOnly>
          {tabs.length > 1 && (
            <TabFiltersWrapper>
              <Tabs tabs={(tabs ?? []) as TabType[]} onTabClick={handleTabClick} selectedTab={selectedTab as TabType} />
            </TabFiltersWrapper>
          )}
        </DesktopOnly>
      </FilterBar>
      <DndProvider backend={HTML5Backend} context={window}>
        <BodyTable>
          <DesktopOnly>
            <FilterChips filters={filtersForSelectedGroupType} onRemoveFilter={handleRemoveFilter} />
          </DesktopOnly>
          <div className='responsive-container'>
            <SwimlaneColumns
              ref={lanesWrapperRef}
              onScroll={(x: any) => {
                const gapsize = 20;
                const scrollunit = (x.target.scrollWidth - gapsize * 5) / 4 + gapsize;
                const index = Math.round(x.target.scrollLeft / scrollunit);
                if (scrolledTab != mobileTabs[index]) setScrolledTab(mobileTabs[index]);
              }}>
              {rehabStates.map((rehabState, index) => (
                <PortfolioLane
                  key={rehabState.apiName}
                  loadingPortfolio={loadingPortfolio}
                  initialColumnData={portfolio?.[rehabState.apiName]}
                  filters={filtersForSelectedGroupType}
                  ref={laneRefs[index]}
                  patientState={currentTabFilter.patientState}
                  locationType={currentTabFilter.locationType}
                  debouncedSearch={search}
                  currentRehabState={rehabState}
                  profile={profile}
                />
              ))}
            </SwimlaneColumns>
          </div>
          <OlioFooter />
        </BodyTable>
      </DndProvider>
    </Portfolio>
  );
}

const TabFiltersWrapper = styled.div`
  background-color: white;
  width: 100%;
  margin-bottom: -24px;
`;

const Portfolio = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const BodyTable = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0;
  padding: 0 24px 24px 24px;
  gap: 24px;
  margin-top: 24px;

  @media ${({ theme }) => theme.devices.desktop} {
    min-height: auto;
    overflow-y: auto;
  }

  .responsive-container {
    flex: 1;
    display: flex;
    justify-content: center;
    max-width: 1184px;
    max-height: 100%;
    min-height: 0;

    @media ${({ theme }) => theme.devices.desktop} {
      min-height: auto;
    }
  }
`;

const SwimlaneColumns = styled.div`
  display: flex;
  width: 100%;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  overscroll-behavior-x: contain;
  gap: 20px;

  @media ${({ theme }) => theme.devices.desktop} {
    gap: 10px;
  }
`;

const AddPatientButton = styled(Button)`
  line-height: 24px;
`;
