import React from 'react';
import styled from 'styled-components';

import FormSubtitle from './FormSubtitle';
import FormTitle from './FormTitle';

interface FormHeaderProps {
  subtitle?: string;
  title?: string;
}

function FormHeader({ title, subtitle }: FormHeaderProps) {
  return (
    <TitleContainer>
      {title && <FormTitle>{title}</FormTitle>}
      {subtitle && <FormSubtitle>{subtitle}</FormSubtitle>}
    </TitleContainer>
  );
}

export default FormHeader;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-basis: 100%;
  margin-bottom: 36px;
`;
