import { useState } from 'react';
import { differenceInCalendarDays } from 'date-fns';
import styled from 'styled-components';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import ActivityInput from 'components/shared/activityInput/ActivityInput';
import useActivityInput from 'components/shared/activityInput/useActivityInput';
import Button from 'components/shared/Button';
import DatePicker from 'components/shared/form/DatePicker';
import Input from 'components/shared/form/Input';
import InputGroup from 'components/shared/form/InputGroup';
import StandardModal, { Actions, PatientName } from 'components/shared/StandardModal';
import { parseDate, simpleDashDate } from 'lib/date';
import LocationEpisode from 'models/LocationEpisode';
import { ReviewStatus } from 'models/Review';
import { activityQueryKeys } from 'services/api/activity';
import { locationEpisodeQueryKeys } from 'services/api/locationEpisodes';
import { updateReview } from 'services/api/reviews';
import { colors } from 'styles/theme/colors';
import { Label } from 'styles/typography';

export type UpdateAuthorizationModalType = {
  setShow: (b: boolean) => void;
  patientName: string;
  locationEpisode: LocationEpisode;
};

export default function UpdateAuthorizationModal(props: UpdateAuthorizationModalType) {
  const { setShow, patientName, locationEpisode } = props;
  const queryClient = useQueryClient();

  const authReview = locationEpisode.activeAuthorizationReview!;

  const { mutate, isPending } = useMutation({
    mutationFn: updateReview,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: locationEpisodeQueryKeys.show({ id: locationEpisode.id }) });
      queryClient.invalidateQueries({ queryKey: activityQueryKeys.base });
      cancel();
    },
  });

  const { note, setNote, resetNote, getSerializedNoteValues } = useActivityInput();

  const [authNumber, setAuthNumber] = useState(authReview.data.authorizationNumber ?? '');
  const [authError, setAuthError] = useState('');

  const parsedDate = parseDate(authReview.data.approvedThroughDate);
  const [startDate, setStartDate] = useState<Date | null>(parsedDate);
  const [dateError, setDateError] = useState('');

  const parsedNextReviewDate = parseDate(authReview.data.nextReviewDate);
  const [nextReviewStartDate, setNextReviewStartDate] = useState<Date | null>(parsedNextReviewDate);

  const parsedValidThroughDate = parseDate(authReview.data.validThroughDate);
  const [validThroughDate, setValidThroughDate] = useState<Date | null>(parsedValidThroughDate);

  const [isUploading, setIsUploading] = useState(false);
  const isAdmittedOrInTreatment = locationEpisode.inAdmission || locationEpisode.inTreatment;

  const submitDisabled = (isAdmittedOrInTreatment && !startDate) || !authNumber || isUploading;

  const cancel = () => {
    resetNote();
    setStartDate(parsedDate);
    setShow(false);
  };

  const submit = () => {
    const noteValues = getSerializedNoteValues();

    mutate({
      id: authReview.id,
      status: ReviewStatus.ACCEPTED,
      include: 'activities.attachments',
      note: noteValues.attachments.length || !!noteValues.plaintext ? getSerializedNoteValues() : undefined,
      authorizationNumber: authNumber,
      approvedThroughDate: isAdmittedOrInTreatment ? simpleDashDate(startDate) : undefined,
      nextReviewDate: isAdmittedOrInTreatment && nextReviewStartDate ? simpleDashDate(nextReviewStartDate) : null,
      ...(locationEpisode.inQueue && {
        validThroughDate: validThroughDate ? simpleDashDate(validThroughDate) : null,
      }),
    });
  };

  const calculateRemainingDays = (date: Date | null) => {
    const days = date ? differenceInCalendarDays(date, new Date()) : 0;
    if (!date || days < 0) {
      return <></>;
    }
    if (days === 1) {
      return <>{days} day from now</>;
    } else {
      return <>{days} days from now</>;
    }
  };

  return (
    <StandardModal title='Update Authorization' onCancel={cancel}>
      <PatientName>Patient: {patientName}</PatientName>
      <FormContainer>
        <InputGroup title='Authorization Number' error={authError} data-cy='authNumber'>
          <Input
            $hasError={!!authError}
            value={authNumber}
            placeholder={'Authorization Number'}
            onChange={(event) => {
              setAuthError(event.target.value ? '' : 'Authorization Number is a required field');
              setAuthNumber(event.target.value);
            }}
            disabled={false}
          />
        </InputGroup>
        {isAdmittedOrInTreatment && (
          <InputGroup title='Approved Through Date' error={dateError} data-cy='approvedThroughDate'>
            <DatePicker
              minDate={new Date()}
              $hasError={!!dateError}
              selected={startDate}
              onChange={(event) => {
                setDateError(event ? '' : 'Approved Through Date is a required field');
                setStartDate(event);
              }}
            />
            <DateSubText>{calculateRemainingDays(startDate)}</DateSubText>
          </InputGroup>
        )}
        {isAdmittedOrInTreatment && (
          <InputGroup title='Next Review Date (optional)' data-cy='nextReviewDate'>
            <DatePicker
              minDate={new Date()}
              selected={nextReviewStartDate}
              onChange={(event) => {
                setNextReviewStartDate(event);
              }}
            />
            <DateSubText>{calculateRemainingDays(nextReviewStartDate)}</DateSubText>
          </InputGroup>
        )}
        {locationEpisode.inQueue && (
          <InputGroup title='Valid Through (optional)' data-cy='validThroughDate'>
            <DatePicker
              minDate={new Date()}
              selected={validThroughDate}
              onChange={(event) => {
                setValidThroughDate(event);
              }}
            />
            <DateSubText>{calculateRemainingDays(validThroughDate)}</DateSubText>
          </InputGroup>
        )}
        <InputGroup title='Notes (optional)'>
          <ActivityInput
            values={note}
            setValues={setNote}
            locationEpisodeId={locationEpisode.id}
            onUploading={setIsUploading}
          />
        </InputGroup>
      </FormContainer>
      <Actions>
        <Button variant='ghost' onClick={cancel}>
          Cancel
        </Button>
        <Button data-cy='clickable' onClick={submit} disabled={submitDisabled} loading={isPending}>
          Confirm
        </Button>
      </Actions>
    </StandardModal>
  );
}

const FormContainer = styled.div`
  padding-bottom: 20px;
`;

const DateSubText = styled(Label)`
  color: ${colors.black75};
  font-style: italic;
  margin: 8px 0 20px;
`;
