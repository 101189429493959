import { createBrowserRouter, Outlet, redirect, RouterProvider } from 'react-router-dom';

import AllPatientsPage from 'components/allPatients/AllPatients';
import AuthError from 'components/AuthError';
import ConnectPatientsPage from 'components/connectPatients/ConnectPatients';
import External from 'components/External';
import InsightsContainer from 'components/insights/InsightsContainer';
import AddPatientPage from 'components/intake/AddPatientPage';
import ConnectPatientPage from 'components/intake/ConnectPatientPage';
import EditPatientPage from 'components/intake/EditPatientPage';
import MainLayout from 'components/layouts/MainLayout';
import LoggedOut from 'components/LoggedOut';
import NotificationPreferences from 'components/notificationPreferences/NotificationPreferences';
import PortfolioPage from 'components/portfolio/Portfolio';
import RootProviders from 'components/Root';
import ThemeProvider from 'components/ThemeProvider';
import { EpisodeProvider } from 'context/episode';

import PatientPage from './components/patient/Patient';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootProviders />,
    children: [
      {
        index: true,
        loader: async () => redirect('/patients'),
      },
      {
        path: '/auth/callback',
        element: <></>,
      },
      {
        path: '/external',
        element: <External />,
      },
      {
        path: '/',
        element: <MainLayout />,
        children: [
          {
            path: '/patients/:episodeId',
            element: (
              <EpisodeProvider>
                <Outlet />
              </EpisodeProvider>
            ),
            children: [
              {
                index: true,
                element: <PatientPage />,
              },
              {
                path: 'edit',
                element: <EditPatientPage />,
              },
            ],
          },
          {
            path: '/all-patients',
            element: <AllPatientsPage />,
          },
          {
            path: '/insights/*',
            element: <InsightsContainer />,
          },
          {
            path: '/connect-patients',
            element: <ConnectPatientsPage />,
          },
          {
            path: '/connect-patients/:id',
            element: <ConnectPatientPage />,
          },
          {
            path: '/patients',
            element: <PortfolioPage />,
          },
          {
            path: '/patients/new',
            element: <AddPatientPage />,
          },
          {
            path: '/preferences',
            element: <NotificationPreferences />,
          },
        ],
      },
    ],
  },
  {
    path: '/',
    element: (
      <ThemeProvider>
        <Outlet />
      </ThemeProvider>
    ),
    children: [
      {
        path: '/logged-out',
        element: <LoggedOut />,
      },
      {
        path: '/error',
        element: <AuthError />,
      },
    ],
  },
  {
    path: '*',
    index: true,
    loader: async () => redirect('/patients'),
  },
]);

export default function App() {
  return <RouterProvider router={router} />;
}
